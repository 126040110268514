import { useEffect, useState } from 'react'
import styles from './summary-statuses.module.css'
import eventsFirebaseRepository, { StreamStatuses } from '../../../repositories/firebase/events.firebase.repository'
import React from 'react'
import { BsFillCameraVideoFill, BsFillCameraVideoOffFill, BsMicFill, BsMicMuteFill, BsRecordCircle, BsSkype } from 'react-icons/bs'

function SummaryStatuses() {

    var streamStatusesChangedHandlerId: number|null = null

    // Use State

    const [streamStatuses, setStreamStatuses] = useState<StreamStatuses|null>(null)

    // Effects

    useEffect(() => {
        streamStatusesChangedHandlerId = eventsFirebaseRepository.registerStreamStatusesChangedHandler(handleStreamStatusesChanged)

        return () => {
            if (streamStatusesChangedHandlerId !== null) {
                eventsFirebaseRepository.unregisterStreamStatusesChangedHandler(streamStatusesChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        eventsFirebaseRepository.loadStatuses().then((streamStatuses) => {
            handleStreamStatusesChanged(streamStatuses)
        })
    }, [])

    // Handler methods

    const handleStreamStatusesChanged = async (streamStatuses: StreamStatuses | null) => {
        setStreamStatuses(streamStatuses)
    }
    
    return (
        <>
            {streamStatuses !== null &&
                <div className={styles.containerStatuses}>
                    {(streamStatuses.isStreaming || streamStatuses.isRecording) &&
                        <div className={styles.containerStatusesTop}>
                            {streamStatuses.isStreaming &&
                                <div className={styles.statusContainer + " " + styles.statusOnAir}>
                                    <div className={styles.statusTitle + " " + styles.statusImportant}>{"ON AIR"}</div>
                                </div>
                            }
                            {streamStatuses.isRecording &&
                                <div className={styles.statusContainer}>
                                    <div className={styles.statusIcon + " " + styles.statusImportant}>{React.createElement(BsRecordCircle)}</div>
                                </div>
                            }
                        </div>
                    }
                    <div className={styles.containerStatusesBottom}>
                        <div className={styles.statusContainer}>
                            <div className={styles.statusIcon}>{streamStatuses.isMuted ? React.createElement(BsMicMuteFill) : React.createElement(BsMicFill)}</div>
                        </div>
                        <div className={styles.statusContainer}>
                            <div className={styles.statusIcon}>{streamStatuses.isCameraOn ? React.createElement(BsFillCameraVideoFill) : React.createElement(BsFillCameraVideoOffFill)}</div>
                        </div>
                        {streamStatuses.isOnAMeeting &&
                            <div className={styles.statusContainer}>
                                <div className={styles.statusIcon}>{React.createElement(BsSkype)}</div>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default SummaryStatuses;