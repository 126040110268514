import styles from './obs-dock-widget.module.css';
import PomodoroConfig from './pomodoro/pomodoro-config.module';
import ExerciseConfig from './exercise/exercise-config.module';
import VisibilityStatuses from './visibility/visibility-statuses.module';
import Tasks from './tasks/tasks.module';

function ObsDockWidget() {
  return (
    <div className={styles.container}>
      <PomodoroConfig />
      {/* <ExerciseConfig /> */}
      <VisibilityStatuses />
      <Tasks />
    </div>
  );
}

export default ObsDockWidget;
