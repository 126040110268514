// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyBg-VkO_ZwfT0esUDSVU8z_MsEXqaV23Ws",
    authDomain: "cp-obs-stream-widgets.firebaseapp.com",
    databaseURL: "https://cp-obs-stream-widgets-default-rtdb.firebaseio.com",
    projectId: "cp-obs-stream-widgets",
    storageBucket: "cp-obs-stream-widgets.appspot.com",
    messagingSenderId: "370587266029",
    appId: "1:370587266029:web:e2938f2012a575dab7d5e4",
    measurementId: "G-4ZCXH5MNVJ"
};
