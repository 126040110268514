import { initializeApp } from 'firebase/app'
import { getDatabase, ref as dbRef, DatabaseReference, get, onValue, DataSnapshot, set } from 'firebase/database'
import { firebaseConfig } from '../../../firebase';
import TaskFirebase from './models/task.firebase.model';

class TasksFirebaseRepository {

    // PROPERTIES

    private firebaseApp = initializeApp(firebaseConfig)
    private firebaseDatabase = getDatabase(this.firebaseApp)

    private handlingTasksChanged: Boolean = false
    private tasksChangedHandlers: (((tasks: TaskFirebase[] | null) => void))[] = []

    // PUBLIC METHODS

    // Tasks

    async loadTasks(): Promise<TaskFirebase[]|null> {
        return new Promise((resolve, reject) => {
            const ref = this.tasksRef()

            get(ref).then((snapshot) => {
                resolve(snapshot.val() !== undefined ? snapshot.val() as TaskFirebase[] : null)

                this.handlingTasksChanged = true
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    async updateTasks(newTasks: TaskFirebase[]): Promise<void> {
        return new Promise((resolve, reject) => {
            const ref = this.tasksRef()

            set(ref, newTasks).then(() => {
                resolve()
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    registerTasksChangedHandler(handler: (tasks: TaskFirebase[] | null) => void): number {
        console.log("registerTasksChangedHandler")

        if (this.tasksChangedHandlers.length === 0) {
            const ref = this.tasksRef()

            onValue(ref, this.onTasksChangedHandler)
        }

        const indexToReturn = this.tasksChangedHandlers?.length || 0

        this.tasksChangedHandlers?.push(handler)

        return indexToReturn
    }

    unregisterTasksChangedHandler(index: number) {
        console.log("unregisterTasksChangedHandler")

        this.tasksChangedHandlers.splice(index, 1)
    }

    // PRIVATE METHODS

    private onTasksChangedHandler = (snapshot: DataSnapshot) => {
        console.log("onTasksChangedHandler")

        if (!this.handlingTasksChanged) {
            return
        }

        const tasks = snapshot.val() !== undefined ? snapshot.val() as TaskFirebase[] : null

        this.tasksChangedHandlers.forEach((tasksChangedHandler) => {
            tasksChangedHandler(tasks)
        })
    }

    // DATABASE REFERENCES

    private tasksRef(): DatabaseReference {
        return dbRef(this.firebaseDatabase, "tasks")
    }
}

const tasksFirebaseRepo = new TasksFirebaseRepository()

export default tasksFirebaseRepo