import React, { useEffect, useState } from 'react';
import styles from './visibility-statuses.module.css';
import modStyles from '../obs-dock-widget.module.css';
import PomodoroVis from './images/PomodoroVis.png';
import TasksVis from './images/TasksVis.png';
import ChatVis from './images/ChatVis.png';
import DiscordVis from './images/DiscordVis.png';
import StatusesVis from './images/StatusesVis.png';
import MeetingVis from './images/Meeting.png';
import BrbVis from './images/BeRightBack.png';
import eventsFirebaseRepository, { StreamStatuses } from '../../../repositories/firebase/events.firebase.repository';

function VisibilityStatuses() {

    // Properties

    var streamStatusesChangedHandlerId: number|null = null

    // Use State

    const [isShowing, setIsShowing] = useState<boolean>(true)
    const [streamStatuses, setStreamStatuses] = useState<StreamStatuses|null>(null)

    // Effects

    useEffect(() => {
        streamStatusesChangedHandlerId = eventsFirebaseRepository.registerStreamStatusesChangedHandler(handleStreamStatusesChanged)

        return () => {
            if (streamStatusesChangedHandlerId !== null) {
                eventsFirebaseRepository.unregisterStreamStatusesChangedHandler(streamStatusesChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        eventsFirebaseRepository.loadStatuses().then((streamStatuses) => {
            handleStreamStatusesChanged(streamStatuses)
        })
    }, [])

    // Handler methods

    const handleStreamStatusesChanged = async (streamStatuses: StreamStatuses | null) => {
        setStreamStatuses(streamStatuses)
    }

    // Handle action methods

    const handleStatusActionTap = (statusName: string, isActive: boolean) => {
        eventsFirebaseRepository.updateStatus(statusName, isActive)
    }

    // Body

    return (
        <div className={"row" + " " + modStyles.sectionContainer}>
            <div className="col">
                <div className="row">
                    <div className="col">
                        <div className={modStyles.sectionHeader} onClick={() => { setIsShowing(!isShowing) }}>{"Visibility Statuses".toUpperCase()}</div>
                    </div>
                </div>
                {isShowing && streamStatuses !== null &&
                    <div id={styles.statusActionsContainer}>
                        <VisibilityToggleItem isActive={streamStatuses.isShowingPomodoro} imagePath={PomodoroVis} didTapCallback={() => { handleStatusActionTap("isShowingPomodoro", !streamStatuses.isShowingPomodoro) }} />
                        <VisibilityToggleItem isActive={streamStatuses.isShowingTasks} imagePath={TasksVis} didTapCallback={() => { handleStatusActionTap("isShowingTasks", !streamStatuses.isShowingTasks) }} />
                        <VisibilityToggleItem isActive={streamStatuses.isShowingChat} imagePath={ChatVis} didTapCallback={() => { handleStatusActionTap("isShowingChat", !streamStatuses.isShowingChat) }} />
                        <VisibilityToggleItem isActive={streamStatuses.isShowingDiscord} imagePath={DiscordVis} didTapCallback={() => { handleStatusActionTap("isShowingDiscord", !streamStatuses.isShowingDiscord) }} />
                        <VisibilityToggleItem isActive={streamStatuses.isShowingStatuses} imagePath={StatusesVis} didTapCallback={() => { handleStatusActionTap("isShowingStatuses", !streamStatuses.isShowingStatuses) }} />
                        <VisibilityToggleItem isActive={streamStatuses.isOnAMeeting} imagePath={MeetingVis} didTapCallback={() => { handleStatusActionTap("isOnAMeeting", !streamStatuses.isOnAMeeting) }} />
                        <VisibilityToggleItem isActive={streamStatuses.willBeRightBack} imagePath={BrbVis} didTapCallback={() => { handleStatusActionTap("willBeRightBack", !streamStatuses.willBeRightBack) }} />
                    </div>
                }
            </div>
        </div>
    );
}

export default VisibilityStatuses

type VisibilityToggleItemProps = {
    isActive: boolean,
    imagePath: string,
    didTapCallback: () => void
}

function VisibilityToggleItem(props: VisibilityToggleItemProps) {
    return (
        <div className={styles.visibilityToggleItemContainer} onClick={props.didTapCallback}>
            <img className={styles.visibilityToggleItemImage + (props.isActive ? '' : ' ' + styles.flagIsOff)} src={props.imagePath} alt="Icon" />
        </div>
    )
}