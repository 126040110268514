class PeriodicEvent {

    // PROPERTIES

    private eventHandlers: (() => void)[] = []

    private timer: NodeJS.Timeout | null = null

    // LIFECYCLE METHODS

    constructor() {
        this.timer = setInterval(() => {
            this.handleCycle()
        }, 1000)
    }

    // PUBLIC METHODS

    registerHandler(handler: () => void): number {
        const indexToReturn = this.eventHandlers.length

        this.eventHandlers.push(handler)

        return indexToReturn
    }

    unregisterHandler(index: number) {
        this.eventHandlers.splice(index, 1)
    }

    // PRIVATE METHODS

    private handleCycle() {
        this.eventHandlers.forEach((periodicEventHandler) => {
            periodicEventHandler()
        })
    }
}

const periodicEvent = new PeriodicEvent()

export default periodicEvent