import { initializeApp } from 'firebase/app'
import { getDatabase, ref as dbRef, DatabaseReference, get, onValue, DataSnapshot } from 'firebase/database'
import { firebaseConfig } from '../../../firebase';
import ChatItemFirebase from './models/chat.firebase.model';

class ChatFirebaseRepository {

    // PROPERTIES

    private firebaseApp = initializeApp(firebaseConfig)
    private firebaseDatabase = getDatabase(this.firebaseApp)

    private handlingChatChanged: Boolean = false
    private chatChangedHandlers: (((chat: ChatItemFirebase[] | null) => void))[] = []

    // PUBLIC METHODS

    // Chat

    async loadChat(): Promise<ChatItemFirebase[]|null> {
        return new Promise((resolve, reject) => {
            const ref = this.chatRef()

            get(ref).then((snapshot) => {
                resolve(snapshot.val() !== undefined ? snapshot.val() as ChatItemFirebase[] : null)

                this.handlingChatChanged = true
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    registerChatChangedHandler(handler: (tasks: ChatItemFirebase[] | null) => void): number {
        if (this.chatChangedHandlers.length === 0) {
            const ref = this.chatRef()

            onValue(ref, this.onChatChangedHandler)
        }

        const indexToReturn = this.chatChangedHandlers?.length || 0

        this.chatChangedHandlers?.push(handler)

        return indexToReturn
    }

    unregisterChatChangedHandler(index: number) {
        this.chatChangedHandlers.splice(index, 1)
    }

    // PRIVATE METHODS

    private onChatChangedHandler = (snapshot: DataSnapshot) => {
        if (!this.handlingChatChanged) {
            return
        }

        const chat = snapshot.val() !== undefined ? snapshot.val() as ChatItemFirebase[] : null

        this.chatChangedHandlers.forEach((chatChangedHandler) => {
            chatChangedHandler(chat)
        })
    }

    // DATABASE REFERENCES

    private chatRef(): DatabaseReference {
        return dbRef(this.firebaseDatabase, "chat")
    }
}

const chatFirebaseRepo = new ChatFirebaseRepository()

export default chatFirebaseRepo