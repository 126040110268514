export default interface TaskFirebase {
    taskId: string,
    username: string,
    description: string,
    dateAdded: string,
    status: TaskStatus,
    index: number
}

export enum TaskStatus {
    ToDo = "todo",
    Done = "done"
}
