import { useEffect, useRef, useState } from 'react'
import styles from './tasks.module.css'
import modStyles from '../stream-widget-v02.module.css'
import Pixel, { PixelRow } from '../components/pixel/pixel'
import TaskFirebase, { TaskStatus } from '../../../repositories/firebase/tasks/models/task.firebase.model'
import tasksFirebaseRepo from '../../../repositories/firebase/tasks/tasks.firebase.repository'
import eventsFirebaseRepository, { StreamStatuses } from '../../../repositories/firebase/events.firebase.repository'

function Tasks() {

    // Properties

    var streamStatusesChangedHandlerId: number|null = null
    var tasksChangedHandlerId: number|null = null

    const tasksScrollBaseDurationInMs = 10000
    const tasksSpacingInRems = 0.6

    // Use Ref

    const refTasksContent = useRef<HTMLInputElement>(null)
    const refTasksInnerContainer = useRef<HTMLInputElement>(null)
    const refTasksBaseContent = useRef<HTMLInputElement>(null)

    // Use State
    
    const [streamStatuses, setStreamStatuses] = useState<StreamStatuses|null>(null)
    const [tasks, setTasks] = useState<TaskFirebase[] | null>(null)

    const [heightTasksContent, setHeightTasksContent] = useState<number>(0)
    const [heightTasksBaseContent, setHeightTasksBaseContent] = useState<number>(0)
    const [heightTasksInnerContainer, setHeightTasksInnerContainer] = useState<number>(0)
    const [hasScrolling, setHasScrolling] = useState<boolean>(false)
    const [duration, setDuration] = useState<number>(0)
    const [transformPercentage, setTransformPercentage] = useState<number>(100)

    // Effects

    useEffect(() => {
        streamStatusesChangedHandlerId = eventsFirebaseRepository.registerStreamStatusesChangedHandler(handleStreamStatusesChanged)

        return () => {
            if (streamStatusesChangedHandlerId !== null) {
                eventsFirebaseRepository.unregisterStreamStatusesChangedHandler(streamStatusesChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        tasksChangedHandlerId = tasksFirebaseRepo.registerTasksChangedHandler(handleTasksChanged)

        return () => {
            if (tasksChangedHandlerId !== null) {
                tasksFirebaseRepo.unregisterTasksChangedHandler(tasksChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        tasksFirebaseRepo.loadTasks().then((tasks) => {
            handleTasksChanged(tasks)
        })
    }, [])

    useEffect(() => {
        recalculateHeights()
    })

    useEffect(() => {
        const isScrollable = heightTasksBaseContent > heightTasksInnerContainer

        if (isScrollable) {
            const heightRatio = (heightTasksBaseContent + convertRemToPixels(tasksSpacingInRems)) / heightTasksInnerContainer

            setDuration(tasksScrollBaseDurationInMs * heightRatio)
            setTransformPercentage(heightRatio * 100)
        } else {
            setDuration(0)
            setTransformPercentage(100)
        }

        setHasScrolling(isScrollable)
    }, [heightTasksContent, heightTasksBaseContent, heightTasksInnerContainer])

    useEffect(() => {
        const handleResize = () => {
            // Perform actions on window resize
            recalculateHeights()
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Handler methods

    const handleStreamStatusesChanged = async (streamStatuses: StreamStatuses | null) => {
        setStreamStatuses(streamStatuses)
    }

    const handleTasksChanged = async (changedTasks: TaskFirebase[] | null) => {
        setTasks(changedTasks)
    }

    // Private methods

    const recalculateHeights = () => {
        setHeightTasksContent(refTasksContent.current?.scrollHeight ?? 0)
        setHeightTasksBaseContent(refTasksBaseContent.current?.scrollHeight ?? 0)
        setHeightTasksInnerContainer(refTasksInnerContainer.current?.clientHeight ?? 0)
    }

    const getDoneTasksCount = () => {
        if (tasks === null) {
            return 0
        }

        const pendingTasks = tasks.filter((task) => {
            return task.status === TaskStatus.Done
        })

        if (pendingTasks === null) {
            return 0
        }

        return pendingTasks.length
    }

    const convertRemToPixels = (rem: number) => {    
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }
    
    return (
        <>
        {streamStatuses !== null && streamStatuses.isShowingTasks &&
            <div id={styles.containerTasks}>
                <div className={styles.tasksHeaderFooterContainer}>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={2} />
                            <Pixel color={"#1f1815"}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel />
                            <Pixel color={"#1f1815"}/>
                            <Pixel color={"#c8c0dc"}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={"#1f1815"}/>
                            <Pixel color={"#c8c0dc"}/>
                            <Pixel color={"#f9f2ef"}/>
                        </div>
                    </div>
                    <div className={styles.tasksHeaderMiddleContainer}></div>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={"#1f1815"}/>
                            <PixelRow numberOfPixels={2} />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={"#c8c0dc"}/>
                            <Pixel color={"#1f1815"}/>
                            <Pixel />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={"#f9f2ef"}/>
                            <Pixel color={"#c8c0dc"}/>
                            <Pixel color={"#1f1815"}/>
                        </div>
                    </div>
                </div>
                <div id={styles.tasksContainer} style={{ "--tasks-spacing": `${tasksSpacingInRems}rem` } as React.CSSProperties}>
                    <div id={styles.tasksHeaderContainer}>
                        <span id={styles.tasksHeaderText}>{`!task`}</span>
                        <span id={styles.tasksHeaderSpacer}></span>
                        {tasks !== null &&
                            <span id={styles.tasksHeaderText}>{`${getDoneTasksCount()}/${tasks.length}`}</span>
                        }
                    </div>
                    <div className={styles.tasksSeparator}></div>
                    {tasks !== null &&
                        <div id={styles.tasksInnerContainer} ref={refTasksInnerContainer} className={hasScrolling ? styles.scrolling:""}>
                            <div id={styles.tasksContent} style={{ "--duration": `${duration}ms`, "--transformPercentage": `-${transformPercentage}%` } as React.CSSProperties} ref={refTasksContent}>
                                <div id={styles.tasksBaseContent} ref={refTasksBaseContent}>
                                    {tasks.map((task, idx) =>
                                        <div key={idx} className={styles.taskItemContainer}>
                                            <span className={styles.chatItemMessageText}>{(task.status === TaskStatus.Done ? "x":"_")}</span>
                                            <div>
                                                <span className={styles.taskItemUsernameText}>{`${task.username}: `}</span>
                                                <span className={styles.chatItemMessageText}>{task.description}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {hasScrolling && tasks.map((task, idx) =>
                                    <div key={idx} className={styles.taskItemContainer}>
                                        <span className={styles.chatItemMessageText}>{(task.status === TaskStatus.Done ? "x":"_")}</span>
                                        <div>
                                            <span className={styles.taskItemUsernameText}>{`${task.username}: `}</span>
                                            <span className={styles.chatItemMessageText}>{task.description}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
                <div className={styles.tasksHeaderFooterContainer}>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={"#1f1815"}/>
                            <Pixel color={"#c8c0dc"}/>
                            <Pixel color={"#f9f2ef"}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel />
                            <Pixel color={"#1f1815"}/>
                            <Pixel color={"#c8c0dc"}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={2} />
                            <Pixel color={"#1f1815"}/>
                        </div>
                    </div>
                    <div className={styles.tasksFooterMiddleContainer}></div>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={"#f9f2ef"}/>
                            <Pixel color={"#c8c0dc"}/>
                            <Pixel color={"#1f1815"}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={"#c8c0dc"}/>
                            <Pixel color={"#1f1815"}/>
                            <Pixel />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={"#1f1815"}/>
                            <PixelRow numberOfPixels={2} />
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Tasks;