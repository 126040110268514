import { useEffect, useState } from 'react';
import styles from './currentsequence.module.css';
import modStyles from '../../obs-dock-widget.module.css';
import { PomoSlotStatus } from '../../../../models/pomo-slot.model';
import { Translations } from '../../../../utils/strings';
import ActionButton from '../../../../components/action-button/action-button';
import { PomoSequence } from '../../../../models/pomo-sequence.model';
import firebaseRepository from '../../../../repositories/firebase/obs-dock-widget.firebase.repository';
import format from 'date-fns/format';
import DateFormats from '../../../../utils/date.utils';
import { parse } from 'date-fns';
import CountdownTimer from '../../../../components/countdown-timer/countdown-timer';
import { BsFillPauseFill, BsFillPlayFill, BsFillSkipEndFill, BsFillStopFill } from 'react-icons/bs';
import useSound from 'use-sound'

function CurrentSequence() {

    // Use State

    const [isAuto, setAuto] = useState<boolean>(false)
    const [currentSequence, setCurrentSequence] = useState<PomoSequence|null>(null)
    const [play] = useSound('media/ff8_melodiesoflife_alarm_sample.mp3')

    // Properties

    var alarmSoundPlayed: Boolean = false

    // Effects

    useEffect(() => {
        firebaseRepository.registerCurrentSequenceChangedHandler(handleCurrentSequenceChanged)

        return () => {
            firebaseRepository.unregisterCurrentSequenceChangedHandler(0)
        }
    }, [])

    useEffect(() => {
        firebaseRepository.loadCurrentSequence().then((currentSequence) => {
            handleCurrentSequenceChanged(currentSequence)
        })
    }, [])

    useEffect(() => {
        firebaseRepository.getCurrentSequenceAuto().then((isAuto) => {
            setAuto(isAuto)
        })
    }, [])

    // Status change methods

    const handleAutoSwitch = () => {
        firebaseRepository.currentSequenceAuto(!isAuto).then(() => {
            setAuto(!isAuto)
        })
    }

    const handleStartResume = () => {
        var tmpSeq = currentSequence

        if (tmpSeq !== null) {
            tmpSeq.slots[tmpSeq.currentSlot].status = PomoSlotStatus.Started
            tmpSeq.slots[tmpSeq.currentSlot].referenceDatetime = format(new Date(), DateFormats.simpleDate)

            firebaseRepository.updateCurrentSequence(tmpSeq).then(() => {
                
            })
        }
    }

    const handlePause = () => {
        var tmpSeq = currentSequence

        if (tmpSeq !== null) {
            tmpSeq.slots[tmpSeq.currentSlot].status = PomoSlotStatus.Paused

            // Get the referenceDateTime as Date object
            const referenceDateTime = parse(tmpSeq.slots[tmpSeq.currentSlot].referenceDatetime, DateFormats.simpleDate, new Date())
            // Get the current time as Date object
            const currentDateTime = new Date()

            // occurredTime = currentDateTime - referenceDateTime
            const occurredTimeInMillis = currentDateTime.getTime() - referenceDateTime.getTime()
            const occurredTimeInSeconds = occurredTimeInMillis / 1000

            // remainingTime = durationTime - occurredTime
            var newRemainingTime = tmpSeq.slots[tmpSeq.currentSlot].remainingTime - occurredTimeInSeconds
            // Let's round up the remaining time
            newRemainingTime = Math.ceil(newRemainingTime)

            tmpSeq.slots[tmpSeq.currentSlot].remainingTime = newRemainingTime

            firebaseRepository.updateCurrentSequence(tmpSeq).then(() => {

            })
        }
    }

    const handleStop = () => {
        var tmpSeq = currentSequence

        if (tmpSeq !== null) {
            tmpSeq.slots[tmpSeq.currentSlot].status = PomoSlotStatus.Stopped
            tmpSeq.slots[tmpSeq.currentSlot].remainingTime = tmpSeq.slots[tmpSeq.currentSlot].durationTime

            firebaseRepository.updateCurrentSequence(tmpSeq).then(() => {
                
            })
        }
    }

    const handleSkip = () => {
        var tmpSeq = currentSequence

        if (tmpSeq !== null) {
            tmpSeq.slots[tmpSeq.currentSlot].status = PomoSlotStatus.Stopped
            tmpSeq.slots[tmpSeq.currentSlot].remainingTime = tmpSeq.slots[tmpSeq.currentSlot].durationTime

            if (tmpSeq.currentSlot < tmpSeq.slots.length-1) {
                tmpSeq.currentSlot += 1
                
                if (isAuto) {
                    handleStartResume()
                }
            } else {
                tmpSeq = null
            }

            firebaseRepository.updateCurrentSequence(tmpSeq).then(() => {
                
            })
        }
    }

    // Handler methods

    const handleCurrentSequenceChanged = async (currentSequence: PomoSequence | null) => {
        console.log("handleCurrentSequenceChanged")

        setCurrentSequence(currentSequence)
    }

    // Event handling methods

    const handleButtonAutoClick = () => {
        handleAutoSwitch()
    }

    const handleButtonStartClick = () => {
        handleStartResume()
    }

    const handleButtonPauseClick = () => {
        handlePause()
    }

    const handleButtonStopClick = () => {
        handleStop()
    }

    const handleButtonSkipClick = () => {
        handleSkip()
    }

    const handleCountdownTimerCompletion = () => {
        console.log("handleCountdownTimerCompletion")

        handleSkip()
        
        if (!alarmSoundPlayed) {
            console.log("handleCountdownTimerCompletion - alarmSoundPlayed = false - playing...")

            play()

            alarmSoundPlayed = true
        }
    }

    return (
        <div className="row">
            <div className="col">
                <div className="row">
                    <div className="col">
                        <div className={modStyles.subsectionHeader}>{Translations.sectionCurrentSequence.toUpperCase()}</div>
                    </div>
                    <div className="col-auto px-1">
                        <ActionButton
                            icon={BsFillPlayFill}
                            action={handleButtonAutoClick}/>
                    </div>
                    <div className="col-auto px-1">
                        <div className={styles.bodyText}>{isAuto ? "AUTO" : "MANUAL"}</div>
                    </div>
                </div>
                {currentSequence === null &&
                    <div className="row">
                        <div className="col">
                            <div className={styles.bodyText}>{Translations.messageCurrentSequenceEmpty}</div>
                        </div>
                    </div>
                }
                {currentSequence !== null &&
                    <>
                        <div className="row">
                            <div className="col">
                                <div className="row align-items-center py-1">
                                    <div className="col-auto">
                                        <div className={styles.subsectionText}>{Translations.subsectionCurrentSlot}</div>
                                    </div>
                                    <div className="col-auto px-1">
                                        <div className={styles.bodyText}>
                                            {currentSequence.slots[currentSequence.currentSlot].status}
                                        </div>
                                    </div>
                                    <div className="col-auto px-1">
                                        <div className={styles.bodyText + " " + styles.countdowntimerContainer}>
                                            <CountdownTimer
                                                referenceDateTime={currentSequence.slots[currentSequence.currentSlot].referenceDatetime}
                                                durationTime={currentSequence.slots[currentSequence.currentSlot].remainingTime}
                                                isActive={currentSequence.slots[currentSequence.currentSlot].status === PomoSlotStatus.Started}
                                                completionCallback={handleCountdownTimerCompletion}/>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="row align-items-center">
                                            <div className="col-auto px-1">
                                                <ActionButton
                                                    icon={BsFillPlayFill}
                                                    action={handleButtonStartClick}
                                                    enabled={currentSequence.slots[currentSequence.currentSlot].status !== PomoSlotStatus.Started}/>
                                            </div>
                                            {currentSequence.slots[currentSequence.currentSlot].status !== PomoSlotStatus.Stopped &&
                                                <div className="col-auto px-1">
                                                    <ActionButton
                                                        icon={BsFillPauseFill}
                                                        action={handleButtonPauseClick}
                                                        enabled={currentSequence.slots[currentSequence.currentSlot].status === PomoSlotStatus.Started}/>
                                                </div>
                                            }
                                            <div className="col-auto px-1">
                                                <ActionButton
                                                    icon={BsFillStopFill}
                                                    action={handleButtonStopClick}
                                                    enabled={currentSequence.slots[currentSequence.currentSlot].status !== PomoSlotStatus.Stopped}/>
                                            </div>
                                            <div className="col-auto px-1">
                                                <ActionButton
                                                    icon={BsFillSkipEndFill}
                                                    action={handleButtonSkipClick}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-1">
                            <div className="col">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className={styles.subsectionText}>{Translations.subsectionSequenceSlots}</div>
                                    </div>
                                    {currentSequence.slots.map((pomoSlot, idx) => 
                                        <div key={idx} className="col-auto px-1">
                                            <div className={styles.containerSequenceSlot + (idx === currentSequence.currentSlot ? " " + styles.selected : "")}>{pomoSlot.type.toString().substring(0,1)} | {(pomoSlot.durationTime / 60)}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default CurrentSequence;
