import { useEffect, useState } from 'react'
import styles from './statuses.module.css'
import eventsFirebaseRepository, { StreamStatuses } from '../../../repositories/firebase/events.firebase.repository'
import StatusLabel from './components/status-label.module'

function Statuses() {

    var streamStatusesChangedHandlerId: number|null = null

    // Use State

    const [streamStatuses, setStreamStatuses] = useState<StreamStatuses|null>(null)

    // Effects

    useEffect(() => {
        streamStatusesChangedHandlerId = eventsFirebaseRepository.registerStreamStatusesChangedHandler(handleStreamStatusesChanged)

        return () => {
            if (streamStatusesChangedHandlerId !== null) {
                eventsFirebaseRepository.unregisterStreamStatusesChangedHandler(streamStatusesChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        eventsFirebaseRepository.loadStatuses().then((streamStatuses) => {
            handleStreamStatusesChanged(streamStatuses)
        })
    }, [])

    // Handler methods

    const handleStreamStatusesChanged = async (streamStatuses: StreamStatuses | null) => {
        setStreamStatuses(streamStatuses)
    }
    
    return (
        <>
            {streamStatuses !== null && streamStatuses.isShowingStatuses &&
                <div className={styles.containerStatuses}>
                    <div className={styles.containerTopStatuses}>
                        <div className={styles.statusIconContainer}>
                            <img className={styles.statusImage} src={"./media/cp_stream_pixel_icons_voice_" + (streamStatuses.isMuted ? "off" : "on") + "_v01.png"} alt={"Ahaha"} />
                        </div>
                        <div className={styles.statusIconContainer}>
                            <img className={styles.statusImage} src={"./media/cp_stream_pixel_icons_reading_" + (streamStatuses.isOnAMeeting || streamStatuses.willBeRightBack ? "off" : "on") + "_v01.png"} alt={"Ahaha"} />
                        </div>
                    </div>
                    {streamStatuses.isOnAMeeting &&
                        <StatusLabel title='on a meeting' />
                    }
                    {streamStatuses.willBeRightBack &&
                        <StatusLabel title='be right back' />
                    }
                </div>
            }
        </>
    )
}

export default Statuses;