import { initializeApp } from 'firebase/app'
import { getDatabase, ref as dbRef, get, DataSnapshot, DatabaseReference, onValue } from 'firebase/database'
import { firebaseConfig } from '../../firebase'
import { PomoSequence } from '../../models/pomo-sequence.model'

class FirebaseRepository {

    // PROPERTIES

    private firebaseApp = initializeApp(firebaseConfig)
    private firebaseDatabase = getDatabase(this.firebaseApp)

    // For modules: CurrentSlot
    private handlingCurrentSequenceChanged: Boolean = false
    private currentSequenceChangedHandlers: (((currentSequence: PomoSequence | null) => void))[] = []

    // PUBLIC METHODS

    // Current Sequence

    async loadCurrentSequence(): Promise<PomoSequence|null> {
        return new Promise((resolve, reject) => {
            const ref = this.currentSequenceRef()

            get(ref).then((snapshot) => {
                resolve(snapshot.val() !== undefined ? snapshot.val() as PomoSequence : null)

                this.handlingCurrentSequenceChanged = true
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    registerCurrentSequenceChangedHandler(handler: (currentSequence: PomoSequence | null) => void): number {
        if (this.currentSequenceChangedHandlers.length === 0) {
            const ref = this.currentSequenceRef()

            onValue(ref, this.onCurrentSequenceChangedHandler)
        }

        const indexToReturn = this.currentSequenceChangedHandlers?.length || 0

        this.currentSequenceChangedHandlers?.push(handler)

        return indexToReturn
    }

    unregisterCurrentSequenceChangedHandler(index: number) {
        this.currentSequenceChangedHandlers.splice(index, 1)
    }

    // PRIVATE METHODS

    private onCurrentSequenceChangedHandler = (sequenceSnapshot: DataSnapshot) => {
        if (!this.handlingCurrentSequenceChanged) {
            return
        }

        const curSeq = sequenceSnapshot.val() !== undefined ? sequenceSnapshot.val() as PomoSequence : null

        this.currentSequenceChangedHandlers.forEach((curSeqChangedHandler) => {
            curSeqChangedHandler(curSeq)
        })
    }

    // DATABASE REFERENCES

    // Sequences

    private currentSequenceRef(): DatabaseReference {
        return dbRef(this.firebaseDatabase, "pomodoro/sequences/current")
    }
}

const streamWidgetFirebaseRepository = new FirebaseRepository()

export default streamWidgetFirebaseRepository
