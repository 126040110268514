import React, { useEffect, useState } from 'react';
import styles from './tasks.module.css';
import modStyles from '../obs-dock-widget.module.css';
import TaskFirebase from '../../../repositories/firebase/tasks/models/task.firebase.model';
import { BsTrash } from 'react-icons/bs';
import ActionButton, { ActionButtonEffect } from '../../../components/action-button/action-button';
import tasksFirebaseRepo from '../../../repositories/firebase/tasks/tasks.firebase.repository';

function Tasks() {

    // Properties

    var tasksChangedHandlerId: number|null = null

    // Use State

    const [isShowing, setIsShowing] = useState<boolean>(true)
    const [tasks, setTasks] = useState<TaskFirebase[] | null>(null)
    const [userSections, setUserSections] = useState<TaskUserSection[]>([])

    // Use Effect

    useEffect(() => {
        tasksChangedHandlerId = tasksFirebaseRepo.registerTasksChangedHandler(handleTasksChanged)

        return () => {
            if (tasksChangedHandlerId !== null) {
                tasksFirebaseRepo.unregisterTasksChangedHandler(tasksChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        tasksFirebaseRepo.loadTasks().then((tasks) => {
            handleTasksChanged(tasks)
        })
    }, [])

    useEffect(() => {
        filterTasksToSections()
    }, [tasks])

    // Event handling methods

    const handleTasksChanged = async (changedTasks: TaskFirebase[] | null) => {
        setTasks(changedTasks)
    }

    // Action handling methods

    const handleOnUserTasksDelete = (username: string) => {

    }

    const handleOnTaskDelete = (taskIndex: number) => {
        
    }

    // Helper methods

    const filterTasksToSections = () => {
        var userTasksByUsername: { [id: string]: UserTask[]} = {}
        var taskUserSections: TaskUserSection[] = []

        tasks?.forEach((task, index) => {
            const userTask: UserTask = {
                globalTaskIndex: index,
                userTaskIndex: userTasksByUsername[task.username]?.length ?? 0,
                task: task
            }

            if (!(task.username in userTasksByUsername)) {
                userTasksByUsername[task.username] = []
            }

            userTasksByUsername[task.username].push(userTask)
        })

        for (let key in userTasksByUsername) {
            const taskUserSection: TaskUserSection = {
                username: key,
                userTasks: userTasksByUsername[key]
            }

            taskUserSections.push(taskUserSection)
        }

        setUserSections(taskUserSections)
    }

    // Body

    return (
        <div className={"row" + " " + modStyles.sectionContainer + " " + styles.tasksContainer}>
            <div className="col">
                <div className="row">
                    <div className="col">
                        <div className={modStyles.sectionHeader} onClick={() => { setIsShowing(!isShowing) }}>{"Tasks".toUpperCase()}</div>
                    </div>
                </div>
                {isShowing && userSections.map((userSection, index) => 
                    <TasksSection key={index} taskUserSection={userSection} deleteUserTasksCallback={handleOnUserTasksDelete} deleteTaskCallback={handleOnTaskDelete}/>
                )}
            </div>
        </div>
    );
}

export default Tasks

type TasksSectionProps = {
    taskUserSection: TaskUserSection,
    deleteUserTasksCallback: (username: string) => void
    deleteTaskCallback: (taskIndex: number) => void
}

function TasksSection(props: TasksSectionProps) {
    return (
        <div>
            <div className={"row px-2" + " " + styles.containerUserTasksSection}>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            {props.taskUserSection.username}
                        </div>
                        <div className="col-auto">
                            <div className="row px-1">
                                <div className="col-auto px-1">
                                    <ActionButton
                                        icon={BsTrash}
                                        effect={ActionButtonEffect.Destructive}
                                        action={() => { props.deleteUserTasksCallback(props.taskUserSection.username) }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {props.taskUserSection.userTasks.map((userTask, index) =>
                    <TaskItem key={index} userTask={userTask} deleteCallback={props.deleteTaskCallback} />
                )}
            </div>
        </div>
    )
}

type TaskItemProps = {
    userTask: UserTask,
    deleteCallback: (taskIndex: number) => void
}

function TaskItem(props: TaskItemProps) {
    return (
        <div>
            <div className={"row px-2" + " " + styles.containerTaskItem}>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            {props.userTask.userTaskIndex + " | " + props.userTask.task.status.toUpperCase() + " | " + props.userTask.task.description}
                        </div>
                        <div className="col-auto">
                            <div className="row px-1">
                                <div className="col-auto px-1">
                                    <ActionButton
                                        icon={BsTrash}
                                        effect={ActionButtonEffect.Destructive}
                                        action={() => { props.deleteCallback(props.userTask.globalTaskIndex) }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

type TaskUserSection = {
    username: string,
    userTasks: UserTask[]
}

type UserTask = {
    globalTaskIndex: number,
    userTaskIndex: number,
    task: TaskFirebase
}
