import { useEffect, useState } from 'react'
import styles from './exercise.module.css'
import eventsFirebaseRepository, { StreamStatuses } from '../../../repositories/firebase/events.firebase.repository'
import exerciseFirebaseRepository, { ExerciseSession } from '../../../repositories/firebase/exercise.firebase.repository'

function Exercise() {

    var streamStatusesChangedHandlerId: number|null = null
    var exerciseCurrentChangedHandlerId: number|null = null

    // Use State
    const [streamStatuses, setStreamStatuses] = useState<StreamStatuses|null>(null)
    const [exerciseCurrent, setExerciseCurrent] = useState<ExerciseSession|null>(null)

    // Effects

    useEffect(() => {
        streamStatusesChangedHandlerId = eventsFirebaseRepository.registerStreamStatusesChangedHandler(handleStreamStatusesChanged)

        return () => {
            if (streamStatusesChangedHandlerId !== null) {
                eventsFirebaseRepository.unregisterStreamStatusesChangedHandler(streamStatusesChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        eventsFirebaseRepository.loadStatuses().then((streamStatuses) => {
            handleStreamStatusesChanged(streamStatuses)
        })
    }, [])

    useEffect(() => {
        exerciseCurrentChangedHandlerId = exerciseFirebaseRepository.registerExerciseCurrentChangedHandler(handleExerciseCurrentChanged)

        return () => {
            if (exerciseCurrentChangedHandlerId !== null) {
                exerciseFirebaseRepository.unregisterExerciseCurrentChangedHandler(exerciseCurrentChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        exerciseFirebaseRepository.loadExerciseCurrent().then((exercise) => {
            handleExerciseCurrentChanged(exercise)
        })
    }, [])

    // Handler methods

    const handleStreamStatusesChanged = async (streamStatuses: StreamStatuses | null) => {
        setStreamStatuses(streamStatuses)
    }

    const handleExerciseCurrentChanged = async (exerciseCurrent: ExerciseSession | null) => {
        setExerciseCurrent(exerciseCurrent)
    }
    
    return (
        <>
        {streamStatuses !== null && streamStatuses.isShowingExercise && exerciseCurrent !== null && exerciseCurrent.items.length > 0 &&
            <div className={styles.containerExercise}>
                <div className={styles.exerciseTitleContainer}>
                    <div className={styles.exerciseTitle}>{"Exercise"}</div>
                </div>
                <div className={styles.exerciseCountersContainer}>
                    {exerciseCurrent.items.map((exerciseItem, idx) =>
                        <div key={idx} className={styles.exerciseCounterItemContainer}>
                            <div className={styles.exerciseCounterItemName}>{exerciseItem.type.name}</div>
                            <div className={styles.exerciseCounterItemValue}>{exerciseItem.value}</div>
                        </div>
                    )}
                </div>
            </div>
        }
        </>
    )
}

export default Exercise;