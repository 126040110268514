import styles from "./savedsequences.module.css";
import modStyles from '../../obs-dock-widget.module.css';
import { useEffect, useState } from "react";
import { Translations } from "../../../../utils/strings";
import { PomoSequence } from "../../../../models/pomo-sequence.model";
import firebaseRepository from "../../../../repositories/firebase/obs-dock-widget.firebase.repository";
import ActionButton, { ActionButtonEffect } from "../../../../components/action-button/action-button";
import { BsPower, BsTrash } from "react-icons/bs";

function SavedSequences() {

    // Use State

    const [savedSequences, setSavedSequences] = useState<PomoSequence[]>([])

    // Effects

    useEffect(() => {
        function setHandler() {
            firebaseRepository.registerSavedSequenceAddedHandler(handleSavedSequenceAdded)
            firebaseRepository.registerSavedSequencesChangedHandler(handleSavedSequencesChanged)
        }

        setHandler()

        return () => {
            firebaseRepository.unregisterSavedSequenceAddedHandler()
            firebaseRepository.unregisterSavedSequencesChangedHandler()
        }
    }, [])

    useEffect(() => {
        function loadData() {
            firebaseRepository.loadSavedSequences().then((sequences) => {
                setSavedSequences(sequences)
            })
        }

        loadData()
    }, [])

    // Handler methods

    const handleSavedSequenceAdded = async (newSequence: PomoSequence) => {
        setSavedSequences((prevSequences) => [...prevSequences, newSequence])
    }

    const handleSavedSequencesChanged = async (savedSequences: PomoSequence[]) => {
        setSavedSequences(savedSequences)
    }

    // Event handling methods

    const handleSetSequence = (index: number) => {
        const selectedSeq = savedSequences[index]

        firebaseRepository.setCurrentSequence(selectedSeq).then(() => {

        })
    }

    const handleDeleteSequence = (index: number) => {
        var savedSeqs = savedSequences.slice()

        savedSeqs.splice(index, 1)

        firebaseRepository.updateSavedSequences(savedSeqs).then(() => {

        })
    }

    return (
        <div className="row">
            <div className="col">
                <div className="row">
                    <div className="col">
                        <div className={modStyles.subsectionHeader}>{Translations.sectionSavedSequences.toUpperCase()}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {savedSequences.map((sequence, idx) =>
                            <SavedSequenceItem key={idx} item={sequence} setCallback={() => { handleSetSequence(idx) }} deleteCallback={() => { handleDeleteSequence(idx) }}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SavedSequences;

type SavedSequenceItemProps = {
    item: PomoSequence,
    setCallback: (() => void),
    deleteCallback: (() => void)
}

function SavedSequenceItem(props: SavedSequenceItemProps) {
    return (
        <div className={"row px-2" + " " + styles.containerSequenceItem}>
            <div className="col">
                <div className="row">
                    <div className="col">
                        <div className="row px-2">
                            {props.item.slots.map((pomoSlot, idx) => 
                                <div key={idx} className="col-auto px-1">
                                    <div className={styles.containerSequenceSlot}>{pomoSlot.type.toString().substring(0,1)} | {(pomoSlot.durationTime / 60)}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="row px-2">
                            <div className="col-auto px-1">
                                <ActionButton
                                    icon={BsPower}
                                    action={props.setCallback}/>
                            </div>
                            <div className="col-auto px-1">
                                <ActionButton
                                    icon={BsTrash}
                                    effect={ActionButtonEffect.Destructive}
                                    action={props.deleteCallback}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}