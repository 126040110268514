import { useEffect, useState } from 'react'
import styles from './chat.module.css'
import modStyles from '../stream-widget-v02.module.css'
import eventsFirebaseRepository, { StreamStatuses } from '../../../repositories/firebase/events.firebase.repository'
import Pixel, { PixelRow } from '../components/pixel/pixel'
import ChatItemFirebase from '../../../repositories/firebase/chat/models/chat.firebase.model'
import chatFirebaseRepo from '../../../repositories/firebase/chat/chat.firebase.repository'

const availableColors: string[] = [
    "#82C4BD",
    "#C4A7B1",
    "#B9B2C4",
    "#C4A887",
    "#80C487",
    "#C4A69A",
    "#86A1C4",
    "#C4B07E",
    "#96C47E",
    "#80A2C4",
    "#C492B0",
    "#8FC487",
    "#C48B8C",
    "#A2C48B",
    "#8884C4",
    "#C4B787",
    "#84C49A",
    "#C48C8C",
    "#9484C4",
    "#C4C08B"
]
var currentColorIndex: number = 0
var colorsByChatUsernames = new Map<string, string>()
const defaultUsernameColor = "#9c9491"

const borderColor = "#1f1815"
const transitionColor = "#c8c0dc"
const contentColor = "#f9f2ef"

function Chat() {

    // Properties

    var streamStatusesChangedHandlerId: number|null = null
    var chatChangedHandlerId: number|null = null

    // Use State
    
    const [streamStatuses, setStreamStatuses] = useState<StreamStatuses|null>(null)
    const [chat, setChat] = useState<ChatItemFirebase[]|null>([])

    /**
     * EFFECTS
     */

    // Statuses Firebase

    useEffect(() => {
        streamStatusesChangedHandlerId = eventsFirebaseRepository.registerStreamStatusesChangedHandler(handleStreamStatusesChanged)

        return () => {
            if (streamStatusesChangedHandlerId !== null) {
                eventsFirebaseRepository.unregisterStreamStatusesChangedHandler(streamStatusesChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        eventsFirebaseRepository.loadStatuses().then((streamStatuses) => {
            handleStreamStatusesChanged(streamStatuses)
        })
    }, [])

    // Chat Firebase

    useEffect(() => {
        chatChangedHandlerId = chatFirebaseRepo.registerChatChangedHandler(handleChatChanged)

        return () => {
            if (chatChangedHandlerId !== null) {
                chatFirebaseRepo.unregisterChatChangedHandler(chatChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        chatFirebaseRepo.loadChat().then((chat) => {
            handleChatChanged(chat)
        })
    }, [])

    // Update scroll position after each state chat changes
    useEffect(() => {
        var objDivInner = document.getElementById(styles.chatInnerContainer);

        if (objDivInner !== null) {
            objDivInner.scrollTop = objDivInner.scrollHeight + 100
        }
    }, [chat])

    // Handler methods

    const getColorForUsername = (username: string): string => {
        if (!colorsByChatUsernames.has(username)) {
            colorsByChatUsernames.set(username, availableColors[currentColorIndex])
            currentColorIndex = (currentColorIndex + 1) % availableColors.length
        }

        return colorsByChatUsernames.get(username) ?? defaultUsernameColor
    }

    const handleStreamStatusesChanged = async (streamStatuses: StreamStatuses | null) => {
        setStreamStatuses(streamStatuses)
    }

    const handleChatChanged = async (changedChat: ChatItemFirebase[] | null) => {
        setChat(changedChat)
    }
    
    return (
        <>
        {streamStatuses !== null && streamStatuses.isShowingChat &&
            <div id={styles.containerChat} style={{ "--color-border": borderColor, "--color-background": contentColor } as React.CSSProperties}>
                <div className={styles.chatHeaderFooterContainer}>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={2} />
                            <Pixel color={borderColor}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel />
                            <Pixel color={borderColor}/>
                            <Pixel color={transitionColor}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={borderColor}/>
                            <Pixel color={transitionColor}/>
                            <Pixel color={contentColor}/>
                        </div>
                    </div>
                    <div className={styles.chatHeaderMiddleContainer}></div>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={borderColor}/>
                            <PixelRow numberOfPixels={2} />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={transitionColor}/>
                            <Pixel color={borderColor}/>
                            <Pixel />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={contentColor}/>
                            <Pixel color={transitionColor}/>
                            <Pixel color={borderColor}/>
                        </div>
                    </div>
                </div>
                <div id={styles.chatContainer}>
                    <div id={styles.chatInnerContainer}>
                        {chat && colorsByChatUsernames && chat.map((chatItem, idx) =>
                            <div key={idx} className={styles.chatItemContainer}>
                                <span style={{ "--color": getColorForUsername(chatItem.username) } as React.CSSProperties} className={styles.chatItemUsernameText}>{chatItem.name}</span>: <span className={styles.chatItemMessageText}>{chatItem.message}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.chatHeaderFooterContainer}>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={borderColor}/>
                            <Pixel color={transitionColor}/>
                            <Pixel color={contentColor}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel />
                            <Pixel color={borderColor}/>
                            <Pixel color={transitionColor}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={2} />
                            <Pixel color={borderColor}/>
                        </div>
                    </div>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={11} color={contentColor}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={11} color={contentColor}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={3} color={borderColor}/>
                            <Pixel color={transitionColor}/>
                            <PixelRow numberOfPixels={6} color={contentColor}/>
                            <Pixel color={transitionColor}/>
                        </div>
                    </div>
                    <div className={styles.chatFooterMiddleContainer}></div>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={contentColor}/>
                            <Pixel color={transitionColor}/>
                            <Pixel color={borderColor}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={transitionColor}/>
                            <Pixel color={borderColor}/>
                            <Pixel />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={borderColor}/>
                            <PixelRow numberOfPixels={2} />
                        </div>
                    </div>
                </div>
                <div className={styles.chatHeaderFooterContainer}>
                <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={3} />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={3} />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={3} />
                        </div>
                    </div>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={3} />
                            <Pixel color={borderColor}/>
                            <PixelRow numberOfPixels={4} color={contentColor}/>
                            <Pixel color={transitionColor}/>
                            <PixelRow numberOfPixels={2} color={borderColor}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={3} />
                            <Pixel color={borderColor}/>
                            <PixelRow numberOfPixels={2} color={contentColor}/>
                            <Pixel color={transitionColor}/>
                            <PixelRow numberOfPixels={2} color={borderColor}/>
                            <PixelRow numberOfPixels={2} />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={3} />
                            <Pixel color={borderColor}/>
                            <Pixel color={transitionColor}/>
                            <PixelRow numberOfPixels={2} color={borderColor}/>
                            <PixelRow numberOfPixels={4} />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={3} />
                            <PixelRow numberOfPixels={2} color={borderColor}/>
                            <PixelRow numberOfPixels={6} />
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Chat;