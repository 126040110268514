import { useEffect, useState } from 'react'
import styles from './stream-widget-v02.module.css'
import Pomodoro from './pomodoro/pomodoro.module';
import Statuses from './statuses/statuses.module';
import eventsFirebaseRepository, { StreamStatuses } from '../../repositories/firebase/events.firebase.repository'
import Exercise from './exercise/exercise.module';
import Chat from './chat/chat.module';
import Discord from './discord/voicechannel-participants/discord.voicechannel-participants.module';
import { pixelSize } from './pixel.config';
import Tasks from './tasks/tasks.module';

function StreamWidgetV02() {

  var streamStatusesChangedHandlerId: number|null = null

  // Use State
  const [streamStatuses, setStreamStatuses] = useState<StreamStatuses|null>(null)
    
  // Effects

  useEffect(() => {
      streamStatusesChangedHandlerId = eventsFirebaseRepository.registerStreamStatusesChangedHandler(handleStreamStatusesChanged)

      return () => {
        if (streamStatusesChangedHandlerId !== null) {
          eventsFirebaseRepository.unregisterStreamStatusesChangedHandler(streamStatusesChangedHandlerId)
      }
      }
  }, [])

  useEffect(() => {
      eventsFirebaseRepository.loadStatuses().then((streamStatuses) => {
          handleStreamStatusesChanged(streamStatuses)
      })
  }, [])

  // Handler methods

  const handleStreamStatusesChanged = async (streamStatuses: StreamStatuses | null) => {
      console.log("handling status on stream widget")
      setStreamStatuses(streamStatuses)
  }

  return (
    <div className={styles.containerContent} style={{ "--pixelSize": pixelSize + "px" } as React.CSSProperties}>
      <div className={styles.containerTopCenterContent}>
        <Discord />
      </div>
      <div className={styles.containerTopContent}>
        <div className={styles.containerTopLeft}>
          <div className={styles.containerTopLeftColumn}>
            <Pomodoro />
            <Exercise />
            <Tasks />
          </div>
        </div>
        <div className={styles.containerTopRight}>
          <Statuses />
        </div>
      </div>
      <div className={styles.containerBottomContent}>
        {/* <Media /> */}
        <div className={styles.containerBottomLeft}>
          <Chat />
        </div>
        <div className={styles.containerBottomRight}>
          &nbsp;
        </div>
      </div>
    </div>
  );
}

export default StreamWidgetV02;
