import { useEffect, useState } from 'react'
import styles from './discord.voicechannel-participants.module.css'
import modStyles from '../../stream-widget-v02.module.css'
import discordRepository from '../../../../repositories/firebase/discord/discord.firebase.repository'
import DiscordChannelFirebase from '../../../../repositories/firebase/discord/models/discord-channel.firebase.model'
import DiscordChannelMemberFirebase from '../../../../repositories/firebase/discord/models/discord-channel-member.firebase.model'
import React from 'react'
import { BsDiscord, BsMicMuteFill } from 'react-icons/bs'
import Pixel, { PixelRow } from '../../components/pixel/pixel'
import eventsFirebaseRepository, { StreamStatuses } from '../../../../repositories/firebase/events.firebase.repository'

const WidgetColors = {
    widgetBorder: "#1a1615",
    widgetBackground: "#332e2b",
    avatarBorder: "#20783e"
}

function Discord() {

    // Properties

    var streamStatusesChangedHandlerId: number|null = null
    var channelInfoChangedHandlerId: number|null = null
    var channelMembersChangedHandlerId: number|null = null

    // Use State

    const [streamStatuses, setStreamStatuses] = useState<StreamStatuses|null>(null)
    const [channelInfo, setChannelInfo] = useState<DiscordChannelFirebase|null>(null)
    const [channelMembers, setChannelMembers] = useState<DiscordChannelMemberFirebase[]|null>(null)
    
    // Use Effects

    useEffect(() => {
        streamStatusesChangedHandlerId = eventsFirebaseRepository.registerStreamStatusesChangedHandler(handleStreamStatusesChanged)

        return () => {
            if (streamStatusesChangedHandlerId !== null) {
                eventsFirebaseRepository.unregisterStreamStatusesChangedHandler(streamStatusesChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        channelInfoChangedHandlerId = discordRepository.registerChannelInfoChangedHandler(handleChannelInfoChanged)

        return () => {
            if (channelInfoChangedHandlerId !== null) {
                discordRepository.unregisterChannelInfoChangedHandler(channelInfoChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        discordRepository.loadChannelInfo().then((channelInfo) => {
            handleChannelInfoChanged(channelInfo)
        })
    }, [])

    useEffect(() => {
        channelMembersChangedHandlerId = discordRepository.registerChannelMembersChangedHandler(handleChannelMembersChanged)

        return () => {
            if (channelMembersChangedHandlerId !== null) {
                discordRepository.unregisterChannelMembersChangedHandler(channelMembersChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        discordRepository.loadChannelMembers().then((channelMembers) => {
            handleChannelMembersChanged(channelMembers)
        })
    }, [])

    // Handler methods

    const handleStreamStatusesChanged = async (streamStatuses: StreamStatuses | null) => {
        setStreamStatuses(streamStatuses)
    }

    const handleChannelInfoChanged = async (channelInfo: DiscordChannelFirebase | null) => {
        setChannelInfo(channelInfo)
    }

    const handleChannelMembersChanged = async (channelMembers: DiscordChannelMemberFirebase[] | null) => {
        var members: DiscordChannelMemberFirebase[] = []

        if (channelMembers !== null && channelMembers.length > 0) {
            for(let i=0 ; i<6 ; i++) {
                members.push(channelMembers[0])
            }
        }

        setChannelMembers(channelMembers)
    }
    
    return (
        <>
        {streamStatuses !== null && streamStatuses.isShowingDiscord && channelInfo !== null && channelMembers !== null &&
            <div className={styles.containerDiscord} style={{ "--colorWidgetBorder": WidgetColors.widgetBorder, "--colorWidgetBackground": WidgetColors.widgetBackground, "--colorAvatarBorder": WidgetColors.avatarBorder } as React.CSSProperties}>
                <div className={styles.widgetHeaderFooterContainer}>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <Pixel />
                            <Pixel color={WidgetColors.widgetBorder}/>
                        </div>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={2} color={WidgetColors.widgetBorder} />
                        </div>
                    </div>
                    <div className={styles.widgetHeaderMiddleContainer}></div>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={WidgetColors.widgetBorder}/>
                            <Pixel />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={2} color={WidgetColors.widgetBorder} />
                        </div>
                    </div>
                </div>
                <div className={styles.containerChannelMembers}>
                    {channelMembers.map((channelMember, idx) =>
                        <div key={idx} className={styles.containerChannelMember}>
                            <img className={styles.channelMemberAvatar} src={channelMember.avatarUrl} alt={channelMember.username + " avatar picture"}/>
                            <div className={styles.containerChannelMemberForeground}>
                                <div className={styles.avatarHeaderFooterContainer}>
                                    <div className={modStyles.pixelColumn}>
                                        <div className={modStyles.pixelRow}>
                                            <Pixel color={WidgetColors.widgetBackground} />
                                            <Pixel color={WidgetColors.avatarBorder}/>
                                        </div>
                                        <div className={modStyles.pixelRow}>
                                            <PixelRow numberOfPixels={2} color={WidgetColors.avatarBorder} />
                                        </div>
                                    </div>
                                    <div className={styles.avatarHeaderMiddleContainer}></div>
                                    <div className={modStyles.pixelColumn}>
                                        <div className={modStyles.pixelRow}>
                                            <Pixel color={WidgetColors.avatarBorder}/>
                                            <Pixel color={WidgetColors.widgetBackground} />
                                        </div>
                                        <div className={modStyles.pixelRow}>
                                            <PixelRow numberOfPixels={2} color={WidgetColors.avatarBorder} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.avatarMiddleContainer}>&nbsp;</div>
                                <div className={styles.avatarHeaderFooterContainer}>
                                    <div className={modStyles.pixelColumn}>
                                        <div className={modStyles.pixelRow}>
                                            <PixelRow numberOfPixels={2} color={WidgetColors.avatarBorder} />
                                        </div>
                                        <div className={modStyles.pixelRow}>
                                            <Pixel color={WidgetColors.widgetBackground} />
                                            <Pixel color={WidgetColors.avatarBorder}/>
                                        </div>
                                    </div>
                                    <div className={styles.avatarFooterMiddleContainer}></div>
                                    <div className={modStyles.pixelColumn}>
                                        <div className={modStyles.pixelRow}>
                                            <PixelRow numberOfPixels={2} color={WidgetColors.avatarBorder} />
                                        </div>
                                        <div className={modStyles.pixelRow}>
                                            <Pixel color={WidgetColors.avatarBorder}/>
                                            <Pixel color={WidgetColors.widgetBackground} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.widgetHeaderFooterContainer}>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={2} color={WidgetColors.widgetBorder} />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel />
                            <Pixel color={WidgetColors.widgetBorder}/>
                        </div>
                    </div>
                    <div className={styles.widgetFooterMiddleContainer}></div>
                    <div className={modStyles.pixelColumn}>
                        <div className={modStyles.pixelRow}>
                            <PixelRow numberOfPixels={2} color={WidgetColors.widgetBorder} />
                        </div>
                        <div className={modStyles.pixelRow}>
                            <Pixel color={WidgetColors.widgetBorder}/>
                            <Pixel />
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Discord;