import { initializeApp } from 'firebase/app'
import { getDatabase, ref as dbRef, get, DataSnapshot, DatabaseReference, onValue, set } from 'firebase/database'
import { firebaseConfig } from '../../firebase'

class EventsFirebaseRepository {

    // PROPERTIES

    private firebaseApp = initializeApp(firebaseConfig)
    private firebaseDatabase = getDatabase(this.firebaseApp)

    // Data change handlers
    private handlingStreamStatusesChanged: Boolean = false
    private streamStatusesChangedHandlers: (((streamStatuses: StreamStatuses | null) => void))[] = []

    // PUBLIC METHODS

    // Statuses

    async loadStatuses(): Promise<StreamStatuses|null> {
        return new Promise((resolve, reject) => {
            const ref = this.streamStatusesRef()

            get(ref).then((snapshot) => {
                resolve(snapshot.val() !== undefined ? snapshot.val() as StreamStatuses : null)

                this.handlingStreamStatusesChanged = true
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    async updateStatus(status: string, isActive: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            const ref = this.streamStatusesFlagRef(status)

            set(ref, isActive).then(() => {
                resolve()
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    registerStreamStatusesChangedHandler(handler: (streamStatuses: StreamStatuses | null) => void): number {
        if (this.streamStatusesChangedHandlers.length === 0) {
            const ref = this.streamStatusesRef()

            onValue(ref, this.onStreamStatusesChangedHandler)
        }

        const indexToReturn = this.streamStatusesChangedHandlers?.length || 0

        this.streamStatusesChangedHandlers?.push(handler)

        return indexToReturn
    }

    unregisterStreamStatusesChangedHandler(index: number) {
        this.streamStatusesChangedHandlers.splice(index, 1)
    }

    // PRIVATE METHODS

    private onStreamStatusesChangedHandler = (sequenceSnapshot: DataSnapshot) => {
        if (!this.handlingStreamStatusesChanged) {
            return
        }

        const streamStatuses = sequenceSnapshot.val() !== undefined ? sequenceSnapshot.val() as StreamStatuses : null

        this.streamStatusesChangedHandlers.forEach((streamStatusesChangedHandler) => {
            streamStatusesChangedHandler(streamStatuses)
        })
    }

    // DATABASE REFERENCES

    // Statuses

    private streamStatusesRef(): DatabaseReference {
        return dbRef(this.firebaseDatabase, "stream/statuses")
    }

    private streamStatusesFlagRef(flag: string): DatabaseReference {
        return dbRef(this.firebaseDatabase, "stream/statuses/"+flag)
    }
}

const eventsFirebaseRepository = new EventsFirebaseRepository()

export default eventsFirebaseRepository

export interface StreamStatuses {
    isCameraOn: boolean
    isMuted: boolean
    isOnAMeeting: boolean
    isStreaming: boolean
    isRecording: boolean
    willBeRightBack: boolean
    
    isShowingChat: boolean
    isShowingDiscord: boolean
    isShowingExercise: boolean
    isShowingMedia: boolean
    isShowingPomodoro: boolean
    isShowingStatuses: boolean
    isShowingTasks: boolean
}