import styles from './pomodoro-config.module.css';
import modStyles from '../obs-dock-widget.module.css';
import CurrentSequence from './current-sequence/currentsequence.module';
import Sequence from './sequence/sequence.module';
import SavedSequences from './saved-sequences/savedsequences.module';
import Stats from './stats/stats.module';
import { useState } from 'react';

function PomodoroConfig() {
  
  const [isShowing, setIsShowing] = useState<boolean>(true)

  return (
    <div className={styles.container + " " + modStyles.sectionContainer}>
        <div className="row">
            <div className="col">
                <div className={modStyles.sectionHeader} onClick={() => { setIsShowing(!isShowing) }}>{"Pomodoro".toUpperCase()}</div>
            </div>
        </div>
        {isShowing &&
          <>
            <CurrentSequence/>
            {/* <Stats/> */}
            <Sequence/>
            <SavedSequences/>
          </>
        }
        
    </div>
  );
}

export default PomodoroConfig;
