import Pixel, { PixelRow } from '../../components/pixel/pixel'
import styles from './status-label.module.css'

export type StatusLabelProps = {
    title: string
}

export default function StatusLabel(props: StatusLabelProps) {
    return (
        <div className={styles.statusTextOuterContainer}>
            <div className={styles.statusHeaderFooterContainer}>
                <div className={styles.pixelColumn}>
                    <div className={styles.pixelRow}>
                        <PixelRow numberOfPixels={2} />
                        <Pixel color={"#1f1815"}/>
                    </div>
                    <div className={styles.pixelRow}>
                        <Pixel />
                        <Pixel color={"#1f1815"}/>
                        <Pixel color={"#c8c0dc"}/>
                    </div>
                    <div className={styles.pixelRow}>
                        <Pixel color={"#1f1815"}/>
                        <Pixel color={"#c8c0dc"}/>
                        <Pixel color={"#f9f2ef"}/>
                    </div>
                </div>
                <div className={styles.statusHeaderMiddleContainer}></div>
                <div className={styles.pixelColumn}>
                    <div className={styles.pixelRow}>
                        <Pixel color={"#1f1815"}/>
                        <PixelRow numberOfPixels={2} />
                    </div>
                    <div className={styles.pixelRow}>
                        <Pixel color={"#c8c0dc"}/>
                        <Pixel color={"#1f1815"}/>
                        <Pixel />
                    </div>
                    <div className={styles.pixelRow}>
                        <Pixel color={"#f9f2ef"}/>
                        <Pixel color={"#c8c0dc"}/>
                        <Pixel color={"#1f1815"}/>
                    </div>
                </div>
            </div>
            <div className={styles.statusTextContainer}>
                {props.title.toUpperCase()}
            </div>
            <div className={styles.statusHeaderFooterContainer}>
                <div className={styles.pixelColumn}>
                    <div className={styles.pixelRow}>
                        <Pixel color={"#1f1815"}/>
                        <Pixel color={"#c8c0dc"}/>
                        <Pixel color={"#f9f2ef"}/>
                    </div>
                    <div className={styles.pixelRow}>
                        <Pixel />
                        <Pixel color={"#1f1815"}/>
                        <Pixel color={"#c8c0dc"}/>
                    </div>
                    <div className={styles.pixelRow}>
                        <PixelRow numberOfPixels={2} />
                        <Pixel color={"#1f1815"}/>
                    </div>
                </div>
                <div className={styles.statusFooterMiddleContainer}></div>
                <div className={styles.pixelColumn}>
                    <div className={styles.pixelRow}>
                        <Pixel color={"#f9f2ef"}/>
                        <Pixel color={"#c8c0dc"}/>
                        <Pixel color={"#1f1815"}/>
                    </div>
                    <div className={styles.pixelRow}>
                        <Pixel color={"#c8c0dc"}/>
                        <Pixel color={"#1f1815"}/>
                        <Pixel />
                    </div>
                    <div className={styles.pixelRow}>
                        <Pixel color={"#1f1815"}/>
                        <PixelRow numberOfPixels={2} />
                    </div>
                </div>
            </div>
        </div>
    )
}