import React, { useEffect, useState } from 'react';
import './sequence.module.css';
import modStyles from '../../obs-dock-widget.module.css';
import { InputGroup, Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { PomoSlot } from '../../../../models/pomo-slot.model';
import { BsFillTrashFill } from 'react-icons/bs';
import { Translations } from '../../../../utils/strings';
import ActionButton from '../../../../components/action-button/action-button';
import { PomoSlotStatus } from '../../../../models/pomo-slot.model';
import { PomoSlotType } from '../../../../models/pomo-slot.model';
import { PomoSequence } from '../../../../models/pomo-sequence.model';
import firebaseRepository from '../../../../repositories/firebase/obs-dock-widget.firebase.repository';

type Triple = {name: string, value: string, type: PomoSlotType}

function Sequence() {

    // Use state

    const [currentSequence, setCurrentSequence] = useState<PomoSlot[]>([])
    const [slotTypes, setSlotTypes] = useState<Triple[]|undefined>(undefined)

    // Constants

    const defaultSlot: PomoSlot = {
        durationTime: 0,
        remainingTime: 0,
        type: PomoSlotType.Focus,
        status: PomoSlotStatus.Stopped,
        referenceDatetime: ""
    }

    // Effects

    useEffect(() => {
        async function fetchData() {
            var slotTypesList: Triple[] = []

            var slotTypes = Object.values(PomoSlotType)
            var slotTypesNames = Object.keys(PomoSlotType)
            var slotTypesValues = Object.values(PomoSlotType)

            slotTypesNames.forEach((name, i) => {
                slotTypesList.push({
                    name: name,
                    value: slotTypesValues[i],
                    type: slotTypes[i]
                })
            })

            setSlotTypes(slotTypesList)
        }
        fetchData()

        return (() => {
            setSlotTypes(undefined)
        })
    }, [])

    // Action methods

    const handleCreateSequence = () => {
        const newSequence: PomoSequence = {
            currentSlot: 0,
            slots: currentSequence
        }

        firebaseRepository.addNewSequence(newSequence).then(() => {
            setCurrentSequence([])
        })
    }

    // Event handling methods

    const handleButtonAddClick = () => {
        var tmpCurSeq = currentSequence.slice()

        tmpCurSeq.push(defaultSlot)

        setCurrentSequence(tmpCurSeq)
    }

    const handleButtonCreateClick = () => {
        handleCreateSequence()
    }

    const handleButtonDeleteSlotClick = (idx: number) => {
        var tmpCurSeq = currentSequence.slice()

        tmpCurSeq.splice(idx, 1)

        setCurrentSequence(tmpCurSeq)
    }

    const handleDropdownSlotTypeOnChange = (idx: number, type: PomoSlotType) => {
        var tmpCurSeq = currentSequence.slice()

        var tmpCurPomo = tmpCurSeq[idx]
        if (tmpCurPomo !== undefined) {
            tmpCurPomo.type = type

            tmpCurSeq[idx] = tmpCurPomo
        }

        setCurrentSequence(tmpCurSeq)
    }

    const handleSlotDurationOnChange = (idx: number, event: React.ChangeEvent<HTMLInputElement>) => {
        var tmpCurSeq = currentSequence.slice()

        var tmpCurPomo = tmpCurSeq[idx]
        if (tmpCurPomo !== undefined) {
            var tmpValue = parseInt(event.target.value)

            if (tmpValue !== undefined) {
                tmpCurPomo.durationTime = tmpValue * 60
                tmpCurPomo.remainingTime = tmpCurPomo.durationTime

                tmpCurSeq[idx] = tmpCurPomo
            }
        }

        setCurrentSequence(tmpCurSeq)
    }

    // Body

    return (
        <div className="row">
            <div className="col">
                <div className="row">
                    <div className="col">
                        <div className={modStyles.subsectionHeader}>{Translations.sectionNewSequence.toUpperCase()}</div>
                    </div>
                    <div className="col-auto">
                        <div className={"row " + modStyles.subsectionActionsContainer}>
                            <div className="col-auto">
                                <ActionButton
                                    title={Translations.actionAddSlot}
                                    action={handleButtonAddClick}/>
                            </div>
                            {currentSequence.length > 0 &&
                                <div className="col-auto">
                                    <ActionButton
                                        title={Translations.actionCreateSequence}
                                        action={handleButtonCreateClick}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    {currentSequence.map((slot, idx) =>
                        <div key={idx} className="col-auto">
                            <InputGroup className="mb-3">
                                <DropdownButton variant="outline-secondary" title={slot.type} id="input-group-dropdown-1">
                                    {slotTypes !== undefined && slotTypes.map((slotType, tIdx) =>
                                        <Dropdown.Item key={tIdx} href="#" as="button" onClick={() => {handleDropdownSlotTypeOnChange(idx, slotType.type)}} defaultValue={slotType.value}>{slotType.name}</Dropdown.Item>
                                    )}
                                </DropdownButton>
                                <Form.Control placeholder="Focus time" aria-label="Username" aria-describedby="basic-addon1" value={(slot.durationTime || 0)/60} onChange={(event) => {handleSlotDurationOnChange(idx, event as any)}}/>
                                <Button variant="outline-secondary" id="button-addon2" onClick={() => {handleButtonDeleteSlotClick(idx)}}>{React.createElement(BsFillTrashFill)}</Button>
                            </InputGroup>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Sequence;
