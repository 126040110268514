import { useEffect, useState } from 'react'
import styles from './pomodoro.module.css'
import modStyles from '../stream-widget-v02.module.css'
import { PomoSequence } from '../../../models/pomo-sequence.model'
import firebaseRepository from '../../../repositories/firebase/stream-widget.firebase.repository'
import CountdownTimer from '../../../components/countdown-timer/countdown-timer'
import { PomoSlotStatus, PomoSlotType } from '../../../models/pomo-slot.model'
import eventsFirebaseRepository, { StreamStatuses } from '../../../repositories/firebase/events.firebase.repository'
import Pixel, { PixelRow } from '../components/pixel/pixel'
import { pixelSize } from '../pixel.config'

const WidgetDimensions = {
    widgetHeight: 192,
    widgetWidth: pixelSize*98
}

const WidgetColors = {
    orbLabelFont: "#1f1815",
    orbLabelBorder: "#1f1815",
    orbLabelBackground: "#f9f2ef",
    orbTimerFont: "#f9f2ef",
    orbTimerFontShadow: ""
}

const WidgetStyleVariables = {
    "--color-orb-timer-font": WidgetColors.orbTimerFont,
    "--color-orb-timer-font-shadow": WidgetColors.orbTimerFontShadow,
    "--color-orb-label-font": WidgetColors.orbLabelFont,
    "--color-orb-label-border": WidgetColors.orbLabelBorder,
    "--color-orb-label-background": WidgetColors.orbLabelBackground,
    "--dimen-widget-width": WidgetDimensions.widgetWidth + "px",
    "--dimen-widget-height": WidgetDimensions.widgetHeight + "px"
} as React.CSSProperties

function Pomodoro() {

    // Handler IDs

    var streamStatusesChangedHandlerId: number|null = null
    var currentSequenceChangedHandlerId: number|null = null

    // Use State

    const [streamStatuses, setStreamStatuses] = useState<StreamStatuses|null>(null)
    const [currentSequence, setCurrentSequence] = useState<PomoSequence|null>(null)

    // Effects

    useEffect(() => {
        currentSequenceChangedHandlerId = firebaseRepository.registerCurrentSequenceChangedHandler(handleCurrentSequenceChanged)

        return () => {
            if (currentSequenceChangedHandlerId !== null) {
                firebaseRepository.unregisterCurrentSequenceChangedHandler(currentSequenceChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        firebaseRepository.loadCurrentSequence().then((currentSequence) => {
            handleCurrentSequenceChanged(currentSequence)
        })
    }, [])

    useEffect(() => {
        streamStatusesChangedHandlerId = eventsFirebaseRepository.registerStreamStatusesChangedHandler(handleStreamStatusesChanged)

        return () => {
            if (streamStatusesChangedHandlerId !== null) {
                eventsFirebaseRepository.unregisterStreamStatusesChangedHandler(streamStatusesChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        eventsFirebaseRepository.loadStatuses().then((streamStatuses) => {
            handleStreamStatusesChanged(streamStatuses)
        })
    }, [])

    // Handler methods

    const handleCurrentSequenceChanged = async (currentSequence: PomoSequence | null) => {
        setCurrentSequence(currentSequence)
    }

    const handleCompletion = async () => {
        // Do nothing
    }

    const handleStreamStatusesChanged = async (streamStatuses: StreamStatuses | null) => {
        console.log("handling status on pomodoro")
        setStreamStatuses(streamStatuses)
    }
    
    return (
        <>
        {streamStatuses !== null && streamStatuses.isShowingPomodoro &&
            <div className={styles.containerPomodoro} style={WidgetStyleVariables}>
                {currentSequence !== null &&
                    <>
                        <PomodoroBar type={currentSequence.slots[currentSequence.currentSlot].type} />
                        <PomodoroTimer currentSequence={currentSequence} handleCompletion={handleCompletion} />
                    </>
                }
                <PomodoroOrb currentSequence={currentSequence} />
            </div>
        }
        </>
    )
}

export default Pomodoro;

interface PomodoroBarProps {
    type: PomoSlotType
}

function PomodoroBar(props: PomodoroBarProps) {

    const pomodoroBarWidth = Math.floor(WidgetDimensions.widgetWidth/pixelSize)
    const pomodoroBarBottomSideWidth = 10
    const pomodoroBarTopWidth = pomodoroBarWidth - pomodoroBarBottomSideWidth
    const pomodoroBarTopLeftPadding = 4

    return (
        <div className={styles.pomodoroBarContainer}>
            <div className={styles.pomodoroBarThirdLayer + " " + modStyles.pixelColumn}>
                <div className={modStyles.pixelRow}>
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-1} color={"#162f45"} />
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={(pomodoroBarWidth-2)-(pomodoroBarBottomSideWidth*2)}/>
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-1} color={"#162f45"} />
                    <Pixel color={"#162f45"}/>
                </div>
                <div className={modStyles.pixelRow}>
                    <Pixel />
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-2} color={"#1d68ab"} />
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={(pomodoroBarWidth-2)-(pomodoroBarBottomSideWidth*2)}/>
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-2} color={"#1d68ab"} />
                    <Pixel color={"#162f45"}/>
                </div>
                <div className={modStyles.pixelRow}>
                    <PixelRow numberOfPixels={2} />
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-3} color={"#1d68ab"} />
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={(pomodoroBarWidth-2)-(pomodoroBarBottomSideWidth*2)}/>
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-3} color={"#1d68ab"} />
                    <Pixel color={"#162f45"}/>
                </div>
                <div className={modStyles.pixelRow}>
                    <Pixel />
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-2} color={"#1d68ab"} />
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={(pomodoroBarWidth-2)-(pomodoroBarBottomSideWidth*2)}/>
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-2} color={"#1d68ab"} />
                    <Pixel color={"#162f45"}/>
                </div>
                <div className={modStyles.pixelRow}>
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-1} color={"#162f45"} />
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={(pomodoroBarWidth-2)-(pomodoroBarBottomSideWidth*2)}/>
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-1} color={"#162f45"} />
                    <Pixel color={"#162f45"}/>
                </div>
            </div>
            <div className={styles.pomodoroBarSecondLayer + " " + modStyles.pixelColumn}>
                <div className={modStyles.pixelRow}>
                    <PixelRow numberOfPixels={pomodoroBarTopLeftPadding}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-pomodoroBarTopLeftPadding} color={"#162f45"}/>
                    <PixelRow numberOfPixels={(pomodoroBarWidth)-(pomodoroBarBottomSideWidth*2)}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-pomodoroBarTopLeftPadding} color={"#162f45"}/>
                </div>
                <div className={modStyles.pixelRow}>
                    <PixelRow numberOfPixels={pomodoroBarTopLeftPadding}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-pomodoroBarTopLeftPadding} color={"#162f45"}/>
                    <PixelRow numberOfPixels={(pomodoroBarWidth)-(pomodoroBarBottomSideWidth*2)}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-pomodoroBarTopLeftPadding} color={"#162f45"}/>
                </div>
                <div className={modStyles.pixelRow}>
                    <PixelRow numberOfPixels={pomodoroBarTopLeftPadding}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-pomodoroBarTopLeftPadding} color={"#162f45"}/>
                    <PixelRow numberOfPixels={(pomodoroBarWidth)-(pomodoroBarBottomSideWidth*2)}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-pomodoroBarTopLeftPadding} color={"#162f45"}/>
                </div>
                <div className={modStyles.pixelRow}>
                    <PixelRow numberOfPixels={pomodoroBarTopLeftPadding}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-pomodoroBarTopLeftPadding} color={"#162f45"}/>
                    <PixelRow numberOfPixels={(pomodoroBarWidth)-(pomodoroBarBottomSideWidth*2)}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-pomodoroBarTopLeftPadding} color={"#162f45"}/>
                </div>
                <div className={modStyles.pixelRow}>
                    <PixelRow numberOfPixels={pomodoroBarTopLeftPadding}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-pomodoroBarTopLeftPadding} color={"#162f45"}/>
                    <PixelRow numberOfPixels={(pomodoroBarWidth)-(pomodoroBarBottomSideWidth*2)}/>
                    <PixelRow numberOfPixels={pomodoroBarBottomSideWidth-pomodoroBarTopLeftPadding} color={"#162f45"}/>
                </div>
            </div>
            <div className={styles.pomodoroBarFirstLayer + " " + modStyles.pixelColumn}>
                <div className={modStyles.pixelRow}>
                    <PixelRow numberOfPixels={pomodoroBarTopLeftPadding}/>
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarTopWidth} color={"#162f45"} />
                    <Pixel color={"#162f45"}/>
                </div>
                <div className={modStyles.pixelRow}>
                    <PixelRow numberOfPixels={pomodoroBarTopLeftPadding}/>
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarTopWidth} color={"#1d68ab"} />
                    <Pixel color={"#162f45"}/>
                </div>
                <div className={modStyles.pixelRow}>
                    <PixelRow numberOfPixels={pomodoroBarTopLeftPadding}/>
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarTopWidth} color={"#1d68ab"} />
                    <Pixel color={"#162f45"}/>
                </div>
                <div className={modStyles.pixelRow}>
                    <PixelRow numberOfPixels={pomodoroBarTopLeftPadding}/>
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarTopWidth} color={"#1d68ab"} />
                    <Pixel color={"#162f45"}/>
                </div>
                <div className={modStyles.pixelRow}>
                    <PixelRow numberOfPixels={pomodoroBarTopLeftPadding}/>
                    <Pixel color={"#162f45"}/>
                    <PixelRow numberOfPixels={pomodoroBarTopWidth} color={"#162f45"} />
                    <Pixel color={"#162f45"}/>
                </div>
            </div>
        </div>
    )
}

interface PomodoroTimerProps {
    currentSequence: PomoSequence,
    handleCompletion: () => void
}

function PomodoroTimer(props: PomodoroTimerProps) {
    return (
        <div className={styles.pomodoroTimerContainer}>
            <div className={styles.mainTimerLabel}>
                <CountdownTimer
                    referenceDateTime={props.currentSequence.slots[props.currentSequence.currentSlot].referenceDatetime}
                    durationTime={props.currentSequence.slots[props.currentSequence.currentSlot].remainingTime}
                    isActive={props.currentSequence.slots[props.currentSequence.currentSlot].status === PomoSlotStatus.Started}
                    completionCallback={props.handleCompletion}/>
            </div>
        </div>
    )
}

interface PomodoroOrbProps {
    currentSequence: PomoSequence|null
}

function PomodoroOrb(props: PomodoroOrbProps) {
    return (
        <div className={styles.pomodoroOrbContainer}>
            <img src={"./media/cp_stream_pixel_pomodoro_orb_focus_v01.png"} alt={"Pomodoro Status Orb"} />
            <div className={styles.pomodoroOrbLabelOuterContainer}>
                <div className={styles.pomodoroOrbLabelInnerContainer}>
                    <div className={styles.pomodoroHeaderFooterContainer}>
                        <div className={modStyles.pixelColumn}>
                            <div className={modStyles.pixelRow}>
                                <Pixel />
                                <Pixel color={WidgetColors.orbLabelBorder}/>
                            </div>
                            <div className={modStyles.pixelRow}>
                                <PixelRow numberOfPixels={2} color={WidgetColors.orbLabelBorder}/>
                            </div>
                        </div>
                        <div className={styles.pomodoroHeaderMiddleContainer}></div>
                        <div className={modStyles.pixelColumn}>
                            <div className={modStyles.pixelRow}>
                                <Pixel color={WidgetColors.orbLabelBorder}/>
                                <Pixel />
                            </div>
                            <div className={modStyles.pixelRow}>
                                <PixelRow numberOfPixels={2} color={WidgetColors.orbLabelBorder}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.pomodoroInnerContainer}>
                        {props.currentSequence === null &&
                            <div className={styles.noTimerContainer}>
                                <div className={styles.noTimerTitle}>{"NO SLOT"}</div>
                            </div>
                        }
                        {props.currentSequence !== null &&
                            <div className={styles.mainTimerContainer}>
                                <div className={styles.mainTimerTitle}>{props.currentSequence.slots[props.currentSequence.currentSlot].type}</div>
                            </div>
                        }
                    </div>
                    <div className={styles.pomodoroHeaderFooterContainer}>
                        <div className={modStyles.pixelColumn}>
                            <div className={modStyles.pixelRow}>
                                <PixelRow numberOfPixels={2} color={WidgetColors.orbLabelBorder}/>
                            </div>
                            <div className={modStyles.pixelRow}>
                                <Pixel />
                                <Pixel color={WidgetColors.orbLabelBorder}/>
                            </div>
                        </div>
                        <div className={styles.pomodoroFooterMiddleContainer}></div>
                        <div className={modStyles.pixelColumn}>
                            <div className={modStyles.pixelRow}>
                                <PixelRow numberOfPixels={2} color={WidgetColors.orbLabelBorder}/>
                            </div>
                            <div className={modStyles.pixelRow}>
                                <Pixel color={WidgetColors.orbLabelBorder}/>
                                <Pixel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}