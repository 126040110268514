import { Translations } from "../utils/strings"

export interface PomoSlot {
    referenceDatetime: string
    durationTime: number,
    remainingTime: number,
    type: PomoSlotType,
    status: PomoSlotStatus
}

export enum PomoSlotType {
    Focus = "FOCUS",
    Break = "BREAK"
}

export enum PomoSlotStatus {
    Started = "STARTED",
    Paused = "PAUSED",
    Stopped = "STOPPED"
}

export const translateStatusToString = (status: PomoSlotStatus) => {
    switch (status) {
        case PomoSlotStatus.Started: return Translations.statusStarted
        case PomoSlotStatus.Paused: return Translations.statusPaused
        case PomoSlotStatus.Stopped: return Translations.statusStopped
    }
}
