import styles from './action-button.module.css'
import { IconType } from 'react-icons';
import React from 'react';

export enum ActionButtonType {
    Primary,
    Secondary
}

export enum ActionButtonEffect {
    Content,
    Positive,
    Destructive,
    Neutral,
    Transparent
}

type Props = {
    type?: ActionButtonType,
    effect?: ActionButtonEffect,
    icon?: IconType,
    title?: string,
    enabled?: boolean,
    action: () => any
}

function ActionButton(props: Props) {
    const getClassFromType = (type?: ActionButtonType) => {
        if (type === undefined) {
            return styles.primary
        } else {
            switch (type) {
            case ActionButtonType.Primary:
                return styles.primary
            case ActionButtonType.Secondary:
                return styles.secondary
            }
        }
    }

    const getClassFromEffect = (effect?: ActionButtonEffect) => {
        if (effect === undefined) {
            return styles.positive
        } else {
            switch (effect) {
            case ActionButtonEffect.Content:
                return styles.content
            case ActionButtonEffect.Positive:
                return styles.positive
            case ActionButtonEffect.Destructive:
                return styles.destructive
            case ActionButtonEffect.Neutral:
                return styles.neutral
            }
        }
    }

    return (
        <div
            className={styles.container + " " + getClassFromType(props.type) + " " + getClassFromEffect(props.effect) + (props.enabled === false ? " " + styles.disabled : "")}
            onClick={() => { if (props.enabled !== false) { props.action() }}}>
            
            <div className={styles.containerContent}>
                {props.icon !== undefined &&
                    <div className={styles.iconContainer}>{React.createElement(props.icon)}</div>
                }
                {props.title !== undefined &&
                    <div className={styles.title}>{props.title}</div>
                }
            </div>
        </div>
    )
}

export default ActionButton