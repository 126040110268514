export let Translations: { [name: string]: string } = {}

Translations.sectionCurrentSequence = "Current Sequence"
Translations.sectionModes = "Modes"
Translations.sectionNewSequence = "New Sequence"
Translations.sectionSavedSequences = "Saved Sequences"
Translations.sectionChatPomos = "Chat Pomos"
Translations.sectionStats = "Stats"

Translations.actionStart = "Start"
Translations.actionResume = "Resume"
Translations.actionPause = "Pause"
Translations.actionStop = "Stop"
Translations.actionSkip = "Skip"
Translations.actionAddSlot = "Add Slot"
Translations.actionCreateSequence = "Create Sequence"
Translations.actionSet = "Set"
Translations.actionDelete = "Delete"

Translations.statusStarted = "Started"
Translations.statusPaused = "Paused"
Translations.statusStopped = "Stopped"

Translations.messageCurrentSequenceEmpty = "No current sequence"

Translations.subsectionCurrentSlot = "Current Slot"
Translations.subsectionSequenceSlots = "Slots"