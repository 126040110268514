import styles from './pixel.module.css'
import modStyles from '../../stream-widget-v02.module.css'
import { pixelSize } from '../../pixel.config'

type Props = {
    color?: string,
    sizeInPixels?: number
}

function Pixel(props: Props) {
    return (
        <div style={{ "--color": (props.color !== undefined ? props.color! : "#ffffff00"), "--sizeInPixels": (props.sizeInPixels !== undefined ? props.sizeInPixels! : pixelSize)+"px" } as React.CSSProperties} className={styles.container}>
        </div>
    )
}

export default Pixel

type PixelRowProps = {
    numberOfPixels: number,
    color?: string,
    sizeInPixels?: number
}

export function PixelRow(props: PixelRowProps) {
    return (
        <div className={modStyles.pixelRow}>
            {[...Array(props.numberOfPixels)].map((e, i) => (
                <Pixel key={i} color={props.color} sizeInPixels={props.sizeInPixels} />
            ))}
        </div>
    )
}