import { initializeApp } from 'firebase/app'
import { getDatabase, ref as dbRef, get, DataSnapshot, DatabaseReference, onValue, set } from 'firebase/database'
import { firebaseConfig } from '../../firebase'

class ExerciseFirebaseRepository {

    // PROPERTIES

    private firebaseApp = initializeApp(firebaseConfig)
    private firebaseDatabase = getDatabase(this.firebaseApp)

    // Data change handlers
    private handlingExerciseCurrentChanged: Boolean = false
    private exerciseCurrentChangedHandlers: (((exerciseCurrent: ExerciseSession | null) => void))[] = []
    private handlingExerciseTypesChanged: Boolean = false
    private exerciseTypesChangedHandlers: (((exerciseTypes: ExerciseType[] | null) => void))[] = []

    // PUBLIC METHODS

    // Current Sequence

    async loadExerciseCurrent(): Promise<ExerciseSession|null> {
        return new Promise((resolve, reject) => {
            const ref = this.exerciseCurrentRef()

            get(ref).then((snapshot) => {
                resolve(snapshot.val() !== undefined ? snapshot.val() as ExerciseSession : null)

                this.handlingExerciseCurrentChanged = true
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    async updateExerciseSession(exerciseSession: ExerciseSession | null): Promise<void> {
        return new Promise((resolve, reject) => {
            const ref = this.exerciseCurrentRef()

            set(ref, exerciseSession).then(() => {
                resolve()
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    registerExerciseCurrentChangedHandler(handler: (exerciseCurrent: ExerciseSession | null) => void): number {
        if (this.exerciseCurrentChangedHandlers.length === 0) {
            const ref = this.exerciseCurrentRef()

            onValue(ref, this.onExerciseCurrentChangedHandler)
        }

        const indexToReturn = this.exerciseCurrentChangedHandlers?.length || 0

        this.exerciseCurrentChangedHandlers?.push(handler)

        return indexToReturn
    }

    unregisterExerciseCurrentChangedHandler(index: number) {
        this.exerciseCurrentChangedHandlers.splice(index, 1)
    }

    async loadExerciseTypes(): Promise<ExerciseType[]|null> {
        return new Promise((resolve, reject) => {
            const ref = this.exerciseTypesRef()

            get(ref).then((snapshot) => {
                resolve(snapshot.val() !== undefined ? snapshot.val() as ExerciseType[] : null)

                this.handlingExerciseTypesChanged = true
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    async updateExerciseTypes(exerciseTypes: ExerciseType[] | null): Promise<void> {
        return new Promise((resolve, reject) => {
            const ref = this.exerciseTypesRef()

            set(ref, exerciseTypes).then(() => {
                resolve()
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    registerExerciseTypesChangedHandler(handler: (exerciseCurrent: ExerciseType[] | null) => void): number {
        if (this.exerciseTypesChangedHandlers.length === 0) {
            const ref = this.exerciseTypesRef()

            onValue(ref, this.onExerciseTypesChangedHandler)
        }

        const indexToReturn = this.exerciseTypesChangedHandlers?.length || 0

        this.exerciseTypesChangedHandlers?.push(handler)

        return indexToReturn
    }

    unregisterExerciseTypesChangedHandler(index: number) {
        this.exerciseTypesChangedHandlers.splice(index, 1)
    }

    // PRIVATE METHODS

    private onExerciseCurrentChangedHandler = (sequenceSnapshot: DataSnapshot) => {
        if (!this.handlingExerciseCurrentChanged) {
            return
        }

        const exerciseCurrent = sequenceSnapshot.val() !== undefined ? sequenceSnapshot.val() as ExerciseSession : null

        this.exerciseCurrentChangedHandlers.forEach((exerciseCurrentChangedHandler) => {
            exerciseCurrentChangedHandler(exerciseCurrent)
        })
    }

    private onExerciseTypesChangedHandler = (sequenceSnapshot: DataSnapshot) => {
        if (!this.handlingExerciseTypesChanged) {
            return
        }

        const exerciseTypes = sequenceSnapshot.val() !== undefined ? sequenceSnapshot.val() as ExerciseType[] : null

        this.exerciseTypesChangedHandlers.forEach((exerciseTypesChangedHandler) => {
            exerciseTypesChangedHandler(exerciseTypes)
        })
    }

    // DATABASE REFERENCES

    // Sequences

    private exerciseCurrentRef(): DatabaseReference {
        return dbRef(this.firebaseDatabase, "exercise/current")
    }

    private exerciseTypesRef(): DatabaseReference {
        return dbRef(this.firebaseDatabase, "exercise/types")
    }
}

const exerciseFirebaseRepository = new ExerciseFirebaseRepository()

export default exerciseFirebaseRepository

export interface ExerciseSession {
    items: ExerciseItem[]
}

export interface ExerciseItem {
    type: ExerciseType
    value: string
}

export interface ExerciseType {
    id: string
    name: string
}