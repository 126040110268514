import { Route, Routes } from 'react-router-dom';
import './App.css';
import ObsDockWidget from './modules/obs-dock-widget/obs-dock-widget.module';
import StreamSummaryWidget from './modules/stream-summary-widget/stream-summary-widget.module';
import StreamWidgetV02 from './modules/stream-widget-v02/stream-widget-v02.module';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/stream-widget-v02" element={<StreamWidgetV02/>}/>
        <Route path="/obs-dock-widget" element={<ObsDockWidget/>}/>
        <Route path="/stream-summary-widget" element={<StreamSummaryWidget/>}/>
      </Routes>
    </div>
  );
}

export default App;
