import { useEffect, useState } from 'react'
import styles from './stream-summary-widget.module.css'
import eventsFirebaseRepository, { StreamStatuses } from '../../repositories/firebase/events.firebase.repository'
import SummaryStatuses from './summary-statuses/summary-statuses.module'
import SummaryPomodoro from './summary-pomodoro/summary-pomodoro.module'

function StreamSummaryWidget() {

  var streamStatusesChangedHandlerId: number|null = null

  // Use State
  const [streamStatuses, setStreamStatuses] = useState<StreamStatuses|null>(null)
    
  // Effects

  useEffect(() => {
      streamStatusesChangedHandlerId = eventsFirebaseRepository.registerStreamStatusesChangedHandler(handleStreamStatusesChanged)

      return () => {
        if (streamStatusesChangedHandlerId !== null) {
          eventsFirebaseRepository.unregisterStreamStatusesChangedHandler(streamStatusesChangedHandlerId)
      }
      }
  }, [])

  useEffect(() => {
      eventsFirebaseRepository.loadStatuses().then((streamStatuses) => {
          handleStreamStatusesChanged(streamStatuses)
      })
  }, [])

  // Handler methods

  const handleStreamStatusesChanged = async (streamStatuses: StreamStatuses | null) => {
      setStreamStatuses(streamStatuses)
  }

  return (
    <div className={styles.containerContent}>
      <SummaryPomodoro />
      <SummaryStatuses />
      {streamStatuses !== null && streamStatuses.isShowingMedia &&
        <div className={styles.containerBottomContent}>
          {/* <Media /> */}
        </div>
      }
    </div>
  );
}

export default StreamSummaryWidget;
