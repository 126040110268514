import { useEffect, useState } from 'react';
import DateFormats from '../../utils/date.utils';
import { parse } from 'date-fns';
import periodicEvent from '../../services/periodic-event';

type CountdownTimerProps = {
    referenceDateTime: string,
    durationTime: number,
    isActive: boolean,
    completionCallback: (() => void)
}

var periodicEventHandlerId: number|undefined = undefined

function CountdownTimer(props: CountdownTimerProps) {
    
    // State

    const [isCountdownActive, setCountdownActive] = useState<boolean>(false)
    const [timerLabel, setTimerLabel] = useState<string>("00:00")

    // Effects

    useEffect(() => {
        registerHandler()

        return () => {
            unregisterHandler()
        }
    }, [])

    useEffect(() => {
        setCountdownActive(props.isActive)

        updateUI()
    }, [props])

    useEffect(() => {
        if (isCountdownActive) {
            registerHandler()
        } else {
            unregisterHandler()
        }

        updateUI()
    }, [isCountdownActive])

    // Handling methods

    function periodicEventHandler() {
        updateUI()
    }

    // Private methods

    function updateUI() {
        if (isCountdownActive) {
            handleCountdownCycle()
        } else {
            setDefaultTimerLabel()
        }
    }

    function handleCountdownCycle() {
        const referenceDateTime = parse(props.referenceDateTime, DateFormats.simpleDate, new Date())
        const referenceDateTimeInMillis = referenceDateTime.getTime()
        const durationTimeInMillis = props.durationTime * 1000

        const endDateTimeInMillis = referenceDateTimeInMillis + durationTimeInMillis
        const currentTimeInMillis = new Date().getTime()

        const timeLeftInMillis = endDateTimeInMillis - currentTimeInMillis

        if (timeLeftInMillis <= 0) {
            setTimerLabel(millisToMinutesAndSeconds(0))

            props.completionCallback()

            return
        }

        const tmpTimerLabel = millisToMinutesAndSeconds(timeLeftInMillis)

        setTimerLabel(tmpTimerLabel)
    }

    function millisToMinutesAndSeconds(millis: number): string {
        var minutes: number = Math.floor(millis / 60000);
        var seconds: number = ((millis % 60000) / 1000) % 60;
    
        return minutes + ":" + (seconds < 10 ? '0' : '') + Math.floor(seconds);
    }

    function setDefaultTimerLabel() {
        const durationTimeInMillis = props.durationTime * 1000

        const tmpTimerLabel = millisToMinutesAndSeconds(durationTimeInMillis)

        setTimerLabel(tmpTimerLabel)
    }

    function registerHandler() {
        unregisterHandler()

        periodicEventHandlerId = periodicEvent.registerHandler(() => {
            periodicEventHandler()
        })
    }

    function unregisterHandler() {
        if (periodicEventHandlerId !== undefined) {
            periodicEvent.unregisterHandler(periodicEventHandlerId)

            periodicEventHandlerId = undefined
        }
    }

    return (
        <div>{timerLabel}</div>
    )
}

export default CountdownTimer