import { useEffect, useState } from 'react'
import styles from './summary-pomodoro.module.css'
import { PomoSequence } from '../../../models/pomo-sequence.model'
import firebaseRepository from '../../../repositories/firebase/stream-widget.firebase.repository'
import CountdownTimer from '../../../components/countdown-timer/countdown-timer'
import { PomoSlotStatus } from '../../../models/pomo-slot.model'
import { ChatItem } from '../../../models/chat-item.model'
import eventsFirebaseRepository, { StreamStatuses } from '../../../repositories/firebase/events.firebase.repository'

function SummaryPomodoro() {

    var currentSequenceChangedHandlerId: number|null = null
    var streamStatusesChangedHandlerId: number|null = null

    // Use State
    const [streamStatuses, setStreamStatuses] = useState<StreamStatuses|null>(null)
    const [currentSequence, setCurrentSequence] = useState<PomoSequence|null>(null)

    // Effects

    useEffect(() => {
        currentSequenceChangedHandlerId = firebaseRepository.registerCurrentSequenceChangedHandler(handleCurrentSequenceChanged)

        return () => {
            if (currentSequenceChangedHandlerId !== null) {
                firebaseRepository.unregisterCurrentSequenceChangedHandler(currentSequenceChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        firebaseRepository.loadCurrentSequence().then((currentSequence) => {
            handleCurrentSequenceChanged(currentSequence)
        })
    }, [])

    useEffect(() => {
        streamStatusesChangedHandlerId = eventsFirebaseRepository.registerStreamStatusesChangedHandler(handleStreamStatusesChanged)

        return () => {
            if (streamStatusesChangedHandlerId !== null) {
                eventsFirebaseRepository.unregisterStreamStatusesChangedHandler(streamStatusesChangedHandlerId)
            }
        }
    }, [])

    useEffect(() => {
        eventsFirebaseRepository.loadStatuses().then((streamStatuses) => {
            handleStreamStatusesChanged(streamStatuses)
        })
    }, [])

    // Handler methods

    const handleCurrentSequenceChanged = async (currentSequence: PomoSequence | null) => {
        setCurrentSequence(currentSequence)
    }

    const handleCompletion = async () => {
        // Do nothing
    }

    const handleStreamStatusesChanged = async (streamStatuses: StreamStatuses | null) => {
        setStreamStatuses(streamStatuses)
    }
    
    return (
        <>
        {streamStatuses !== null && streamStatuses.isShowingPomodoro &&
            <div className={styles.containerPomodoro}>
                {currentSequence === null &&
                    <div className={styles.noTimerContainer}>
                        <div className={styles.noTimerTitle}>{"NO SLOT"}</div>
                    </div>
                }
                {currentSequence !== null &&
                    <div className={styles.mainTimerContainer}>
                        <div className={styles.mainTimerTitle}>{currentSequence.slots[currentSequence.currentSlot].type}</div>
                        <div className={styles.mainTimerLabel}>
                            <CountdownTimer
                                referenceDateTime={currentSequence.slots[currentSequence.currentSlot].referenceDatetime}
                                durationTime={currentSequence.slots[currentSequence.currentSlot].remainingTime}
                                isActive={currentSequence.slots[currentSequence.currentSlot].status === PomoSlotStatus.Started}
                                completionCallback={handleCompletion}/>
                        </div>
                    </div>
                }
            </div>
        }
        </>
    )
}

export default SummaryPomodoro;