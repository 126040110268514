import { initializeApp } from 'firebase/app'
import { getDatabase, ref as dbRef, DatabaseReference, get, onValue, DataSnapshot } from 'firebase/database'
import { firebaseConfig } from '../../../firebase';
import DiscordChannelMemberFirebase from "./models/discord-channel-member.firebase.model";
import DiscordChannelFirebase from "./models/discord-channel.firebase.model";

const COWORKING_CHANNEL_ID = "1088523810854150248"

class DiscordFirebaseRepository {
    
    // PROPERTIES

    private firebaseApp = initializeApp(firebaseConfig)
    private firebaseDatabase = getDatabase(this.firebaseApp)

    // Data change handlers
    private handlingChannelInfoChanged: Boolean = false
    private channelInfoChangedHandlers: (((exerciseCurrent: DiscordChannelFirebase | null) => void))[] = []
    private handlingChannelMembersChanged: Boolean = false
    private channelMembersChangedHandlers: (((exerciseTypes: DiscordChannelMemberFirebase[] | null) => void))[] = []

    // PUBLIC METHODS

    // Channel Info

    async loadChannelInfo(): Promise<DiscordChannelFirebase|null> {
        return new Promise((resolve, reject) => {
            const ref = this.channelInfoRef(COWORKING_CHANNEL_ID)

            get(ref).then((snapshot) => {
                resolve(snapshot.val() !== undefined ? snapshot.val() as DiscordChannelFirebase : null)

                this.handlingChannelInfoChanged = true
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    registerChannelInfoChangedHandler(handler: (channelInfo: DiscordChannelFirebase | null) => void): number {
        if (this.channelInfoChangedHandlers.length === 0) {
            const ref = this.channelInfoRef(COWORKING_CHANNEL_ID)

            onValue(ref, this.onChannelInfoChangedHandler)
        }

        const indexToReturn = this.channelInfoChangedHandlers?.length || 0

        this.channelInfoChangedHandlers?.push(handler)

        return indexToReturn
    }

    unregisterChannelInfoChangedHandler(index: number) {
        this.channelInfoChangedHandlers.splice(index, 1)
    }

    // Channel Members

    async loadChannelMembers(): Promise<DiscordChannelMemberFirebase[]|null> {
        return new Promise((resolve, reject) => {
            const ref = this.channelParticipantsRef(COWORKING_CHANNEL_ID)

            get(ref).then((snapshot) => {
                resolve(snapshot.val() !== undefined ? snapshot.val() as DiscordChannelMemberFirebase[] : null)

                this.handlingChannelMembersChanged = true
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    registerChannelMembersChangedHandler(handler: (channelMembers: DiscordChannelMemberFirebase[] | null) => void): number {
        if (this.channelMembersChangedHandlers.length === 0) {
            const ref = this.channelParticipantsRef(COWORKING_CHANNEL_ID)

            onValue(ref, this.onChannelMembersChangedHandler)
        }

        const indexToReturn = this.channelMembersChangedHandlers?.length || 0

        this.channelMembersChangedHandlers?.push(handler)

        return indexToReturn
    }

    unregisterChannelMembersChangedHandler(index: number) {
        this.channelMembersChangedHandlers.splice(index, 1)
    }

    // PRIVATE METHODS

    private onChannelInfoChangedHandler = (sequenceSnapshot: DataSnapshot) => {
        if (!this.handlingChannelInfoChanged) {
            return
        }

        const channelInfo = sequenceSnapshot.val() !== undefined ? sequenceSnapshot.val() as DiscordChannelFirebase : null

        this.channelInfoChangedHandlers.forEach((channelInfoChangedHandler) => {
            channelInfoChangedHandler(channelInfo)
        })
    }

    private onChannelMembersChangedHandler = (sequenceSnapshot: DataSnapshot) => {
        if (!this.handlingChannelMembersChanged) {
            return
        }

        const channelMembers = sequenceSnapshot.val() !== undefined ? sequenceSnapshot.val() as DiscordChannelMemberFirebase[] : null

        this.channelMembersChangedHandlers.forEach((channelMembersChangedHandler) => {
            channelMembersChangedHandler(channelMembers)
        })
    }

    // DATABASE REFERENCES

    private channelInfoRef(channelId: string): DatabaseReference {
        return dbRef(this.firebaseDatabase, "discord/channel/" + channelId + "/info")
    }

    private channelParticipantsRef(channelId: string): DatabaseReference {
        return dbRef(this.firebaseDatabase, "discord/channel/" + channelId + "/participants")
    }
}

const discordFirebaseRepo = new DiscordFirebaseRepository()

export default discordFirebaseRepo
