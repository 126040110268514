import { initializeApp } from 'firebase/app'
import { getDatabase, ref as dbRef, set, onChildAdded, get, DataSnapshot, DatabaseReference, onValue, Unsubscribe } from 'firebase/database'
import { firebaseConfig } from '../../firebase'
import { PomoSequence } from '../../models/pomo-sequence.model'
import { SequencesStats } from '../../models/sequences-stats.model'

class ObsDockWidgetFirebaseRepository {

    // PROPERTIES

    private firebaseApp = initializeApp(firebaseConfig)
    private firebaseDatabase = getDatabase(this.firebaseApp)

    // For modules: CurrentSlot
    private handlingCurrentSequenceChanged: Boolean = false
    private currentSequenceChangedHandlers: (((currentSequence: PomoSequence | null) => void))[] = []
    private handlingSequencesStatsChanged: Boolean = false
    private sequencesStatsChangedHandler: ((stats: SequencesStats | null) => void) | null = null
    private sequencesStatsChangedUnsubscribe: Unsubscribe | null = null

    // For module: SavedSequences
    private handlingSavedSequenceAdded: Boolean = false
    private handlingSavedSequencesChanged: Boolean = false
    private savedSequenceAddedUnsubscribe: Unsubscribe | null = null
    private savedSequenceAddedHandler: ((newSavedSequence: PomoSequence) => void) | null = null
    private savedSequencesChangedUnsubscribe: Unsubscribe | null = null
    private savedSequencesChangedHandler: ((savedSequences: PomoSequence[]) => void) | null = null

    // PUBLIC METHODS

    // Current Sequence

    async loadCurrentSequence(): Promise<PomoSequence|null> {
        return new Promise((resolve, reject) => {
            const ref = this.currentSequenceRef()

            get(ref).then((snapshot) => {
                resolve(snapshot.val() !== undefined ? snapshot.val() as PomoSequence : null)

                this.handlingCurrentSequenceChanged = true
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    async updateCurrentSequence(currentSequence: PomoSequence | null): Promise<void> {
        return new Promise((resolve, reject) => {
            const ref = this.currentSequenceRef()

            set(ref, currentSequence).then(() => {
                resolve()
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    async currentSequenceAuto(isAuto: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            const ref = this.sequenceAutoRef()

            set(ref, isAuto).then(() => {
                resolve()
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    async getCurrentSequenceAuto(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const ref = this.sequenceAutoRef()

            get(ref).then((snapshot) => {
                resolve(snapshot.val() !== undefined ? snapshot.val() as boolean : false)

                this.handlingCurrentSequenceChanged = true
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    registerCurrentSequenceChangedHandler(handler: (currentSequence: PomoSequence | null) => void): number {
        if (this.currentSequenceChangedHandlers.length === 0) {
            console.log("this.currentSeq == 0")
            const ref = this.currentSequenceRef()

            onValue(ref, this.onCurrentSequenceChangedHandler)
        }

        const indexToReturn = this.currentSequenceChangedHandlers?.length || 0

        this.currentSequenceChangedHandlers?.push(handler)

        return indexToReturn
    }

    unregisterCurrentSequenceChangedHandler(index: number) {
        this.currentSequenceChangedHandlers.splice(index, 1)
    }

    // Sequences Stats

    async loadSequencesStats(): Promise<SequencesStats | null> {
        return new Promise((resolve, reject) => {
            const ref = this.sequencesStatsRef()

            get(ref).then((snapshot) => {
                resolve(snapshot.val() !== undefined ? snapshot.val() as SequencesStats : null)

                this.handlingSequencesStatsChanged = true
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    async updateSequencesStats(stats: SequencesStats | null): Promise<void> {
        return new Promise((resolve, reject) => {
            const ref = this.sequencesStatsRef()

            set(ref, stats).then(() => {
                resolve()
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    registerSequencesStatsChangedHandler(handler: (stats: SequencesStats | null) => void) {
        const ref = this.sequencesStatsRef()
        this.sequencesStatsChangedUnsubscribe = onValue(ref, this.onSequencesStatsChangedHandler)

        this.sequencesStatsChangedHandler = handler
    }

    unregisterSequencesStatsChangedHandler() {
        this.sequencesStatsChangedHandler = null
        this.sequencesStatsChangedUnsubscribe?.()
    }

    // New Sequence

    async addNewSequence(newSequence: PomoSequence): Promise<void> {
        return new Promise((resolve, reject) => {
            const ref = this.savedSequencesRef()

            get(ref).then((snapshot) => {

                const count = snapshot.val().length

                const newRef = this.newSequenceRef(count)

                set(newRef, newSequence).then(() => {
                    resolve()
                }).catch((error) => {
                    console.error(error)

                    reject(error)
                })
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    // Saved Sequences

    async loadSavedSequences(): Promise<PomoSequence[]> {
        return new Promise((resolve, reject) => {
            const ref = this.savedSequencesRef()
    
            get(ref).then((snapshot) => {

                var sequences: PomoSequence[] = snapshot.val() !== undefined ? snapshot.val() as PomoSequence[] : []
        
                resolve(sequences)
        
                this.handlingSavedSequenceAdded = true
                this.handlingSavedSequencesChanged = true
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    async setCurrentSequence(newCurrentSequence: PomoSequence): Promise<void> {
        return new Promise((resolve, reject) => {
            const ref = this.currentSequenceRef()

            set(ref, newCurrentSequence).then(() => {
                resolve()
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    async updateSavedSequences(newSavedSequences: PomoSequence[]): Promise<void> {
        return new Promise((resolve, reject) => {
            const ref = this.savedSequencesRef()

            set(ref, newSavedSequences).then(() => {
                resolve()
            }).catch((error) => {
                console.error(error)

                reject(error)
            })
        })
    }

    registerSavedSequenceAddedHandler(handler: (newSequence: PomoSequence) => void) {
        const ref = this.savedSequencesRef()

        this.savedSequenceAddedUnsubscribe = onChildAdded(ref, this.onSavedSequenceAddedHandler)

        this.savedSequenceAddedHandler = handler
    }

    unregisterSavedSequenceAddedHandler() {
        this.savedSequenceAddedHandler = null
        this.savedSequenceAddedUnsubscribe?.()
    }

    registerSavedSequencesChangedHandler(handler: (savedSequences: PomoSequence[]) => void) {
        const ref = this.savedSequencesRef()
        this.savedSequencesChangedUnsubscribe = onValue(ref, this.onSavedSequencesChangedHandler)

        this.savedSequencesChangedHandler = handler
    }

    unregisterSavedSequencesChangedHandler() {
        this.savedSequencesChangedHandler = null
        this.savedSequencesChangedUnsubscribe?.()
    }

    // PRIVATE METHODS

    private onCurrentSequenceChangedHandler = (sequenceSnapshot: DataSnapshot) => {
        if (!this.handlingCurrentSequenceChanged) {
            return
        }

        const curSeq = sequenceSnapshot.val() !== undefined ? sequenceSnapshot.val() as PomoSequence : null

        this.currentSequenceChangedHandlers.forEach((curSeqChangedHandler) => {
            curSeqChangedHandler(curSeq)
        })
    }

    private onSequencesStatsChangedHandler = (snapshot: DataSnapshot) => {
        if (!this.handlingSequencesStatsChanged) {
            return
        }

        const stats = snapshot.val() !== undefined ? snapshot.val() as SequencesStats : null

        this.sequencesStatsChangedHandler?.(stats)
    }

    private onSavedSequenceAddedHandler = (snapshop: DataSnapshot) => {
        if (!this.handlingSavedSequenceAdded) {
            return
        }

        const newSeq = snapshop.val() !== undefined ? snapshop.val() as PomoSequence : null

        if (newSeq !== null) {
            this.savedSequenceAddedHandler?.(newSeq)
        }
    }

    private onSavedSequencesChangedHandler = (snapshot: DataSnapshot) => {
        if (!this.handlingSavedSequencesChanged) {
            return
        }

        const sequences: PomoSequence[] = snapshot.val() !== undefined ? snapshot.val() as PomoSequence[] : []

        this.savedSequencesChangedHandler?.(sequences)
    }

    // DATABASE REFERENCES

    // Sequences

    private sequenceAutoRef(): DatabaseReference {
        return dbRef(this.firebaseDatabase, "pomodoro/sequences/isAuto")
    }

    private currentSequenceRef(): DatabaseReference {
        return dbRef(this.firebaseDatabase, "pomodoro/sequences/current")
    }

    private sequencesStatsRef(): DatabaseReference {
        return dbRef(this.firebaseDatabase, "pomodoro/sequences/stats")
    }

    private savedSequencesRef(): DatabaseReference {
        return dbRef(this.firebaseDatabase, "pomodoro/sequences/saved")
    }

    private newSequenceRef(index: number): DatabaseReference {
        return dbRef(this.firebaseDatabase, "pomodoro/sequences/saved/"+index)
    }
}

const obsDockWidgetFirebaseRepository = new ObsDockWidgetFirebaseRepository()

export default obsDockWidgetFirebaseRepository
